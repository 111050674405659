import {
  Button,
  Modal,
  icons,
  Input,
  SelectInput,
  SelectInputOption,
  H6,
  Form,
  Icon,
} from "@danfoss/etui-core";
import { useEffect, useState } from "react";
import { Div, P } from "@danfoss/etui-system-elements";
import {
  CANCEL_TEXT,
  REPLACE,
  REPLACE_MESSAGE,
  SUCCESS_TEXT,
  FILE_EXIST_MESSAGE,
  FILE_NAME_TEXT,
  FILE_TYPE_TEXT,
  JSON_EXTENSION,
  NO_TEXT,
  REPLACE_PROJECT_TEXT,
  REPLACED_MESSAGE,
  SAVEAS_TEXT,
  SAVE_MESSAGE,
  SAVE_TEXT,
  XML_EXTENSION,
  YES_Text,
  REPLACE_TYPE,
  SPECIAL_CHAR_TEXT,
  FILENAME_WARN_TEXT,
  OK_TEXT,
  UNSUPPORTED_CHAR_TEXT,
} from "../constants";
import { useLayout } from "../components/context/layoutConfig";
import { saveAsPopupProps, saveAsData, eventData } from "../types";
import { getSaveAsProjectData } from "../Services/saveAsFileApi";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import { fileNameValid } from "../utils/InputValidation";

export const SaveAsPopup: React.FC<saveAsPopupProps> = ({ saveAsvalue }) => {
  const { defaultProjectData, openProject, openedProjectName, isElectronApp } =
    useLayout();
  const [openSaveAsPopup, setOpenSaveAsPopup] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [selectedFileType, setSelectedFileType] = useState(JSON_EXTENSION);
  const [openReplacePopup, setOpenReplacePopup] = useState<boolean>(false);
  const [saveAsValues, setsaveAsvalues] = useState<saveAsData>(
    {} as saveAsData
  );
  const [fileOption, setFileOption] = useState<SelectInputOption[]>([
    { value: JSON_EXTENSION, label: JSON_EXTENSION },
  ]);
  const [openInfoPopup, setOpenInfoPopup] = useState<boolean>(false);

  useEffect(() => {
    const options = isElectronApp
      ? [
          { value: JSON_EXTENSION, label: JSON_EXTENSION },
          { value: XML_EXTENSION, label: XML_EXTENSION },
        ]
      : [{ value: JSON_EXTENSION, label: JSON_EXTENSION }];
    setFileOption(options);
  }, [isElectronApp]);

  const handleSaveAsPopup = async () => {
    setOpenSaveAsPopup(true);
    setSelectedFileType(JSON_EXTENSION);
  };
  const handleSaveAsClose = () => {
    setOpenSaveAsPopup(false);
  };
  const handleFileType = (e: SelectInputOption) => {
    setSelectedFileType(e.value);
  };

  const handleReplaceOpen = () => {
    setOpenReplacePopup(true);
  };
  const handleReplaceClose = () => {
    setOpenReplacePopup(false);
  };
  const handleReplace = async () => {
    const saveAsRes = await getSaveAsProjectData(
      saveAsValues,
      true,
      openProject,
      openedProjectName.replace(REPLACE_TYPE, ""),
      isElectronApp
    );
    setOpenReplacePopup(false);
    setOpenSaveAsPopup(false);
    if (saveAsRes === REPLACED_MESSAGE) {
      setOpenSaveAsPopup(false);
      Notification.success({
        message: SUCCESS_TEXT,
        description: REPLACED_MESSAGE,
        duration: 3,
        theme: defaultTheme,
      });
    }
  };
  const handleSaveAs = async () => {
    const saveAsData = {
      saveAsName: fileName,
      fileType: selectedFileType,
      defaultFileContent: defaultProjectData,
      saveFileContent: saveAsvalue,
    };
    setsaveAsvalues(saveAsData);
    const saveAsRes = await getSaveAsProjectData(
      saveAsData,
      false,
      openProject,
      openedProjectName,
      isElectronApp
    );
    if (saveAsData) {
      if (saveAsRes === FILE_EXIST_MESSAGE) {
        handleReplaceOpen();
      } else {
        if (saveAsRes === SAVE_MESSAGE) {
          setOpenSaveAsPopup(false);
          Notification.success({
            message: SUCCESS_TEXT,
            description: SAVE_MESSAGE,
            duration: 3,
            theme: defaultTheme,
          });
        }
      }
    }
  };

  const handleInputChange = (e: eventData) => {
    const isValid = fileNameValid(e.target.value);
    if (isValid === false) {
      setOpenInfoPopup(true);
    } else {
      setFileName(e.target.value);
    }
  };
  const handleInfoClose = () => {
    setOpenInfoPopup(false);
  };

  return (
    <Div>
      <Button
        small={true}
        onClick={handleSaveAsPopup}
        iconProps={{ glyph: icons.COPY }}
        styles={{
          root: {
            height: "35px",
            top: "13.5px",
            color: "black !important",
            left: "601px",
          },
        }}
      >
        {SAVEAS_TEXT}
      </Button>
      <Modal
        style={{
          content: { maxWidth: "600px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{
          contentContainer: {
            height: isElectronApp ? "270px" : "160px",
            width: isElectronApp ? "400px" : "350px",
          },
        }}
        fullPageSize={false}
        isOpen={openSaveAsPopup}
        onClose={handleSaveAsClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={SAVEAS_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleSaveAs}
              styles={{
                root: { width: "80px", height: "30px", mr: "0.5rem" },
              }}
            >
              {SAVE_TEXT}
            </Button>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleSaveAsClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {CANCEL_TEXT}
            </Button>
          </Div>
        }
      >
        <Form styles={{ root: { pl: "20px", pr: "20px", pt: "10px" } }}>
          <H6>{FILE_NAME_TEXT}</H6>
          <Input
            type="text"
            size="small"
            placeholder={FILE_NAME_TEXT}
            onChange={(e) => {
              handleInputChange(e);
            }}
          ></Input>
          {isElectronApp ? (
            <Div>
              <H6>{FILE_TYPE_TEXT}</H6>
              <SelectInput
                name={FILE_TYPE_TEXT}
                options={fileOption}
                defaultValue={fileOption[0]}
                size="small"
                onChange={(e) => {
                  handleFileType(e);
                }}
              ></SelectInput>
            </Div>
          ) : (
            ""
          )}
        </Form>
      </Modal>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "160px", width: "350px" } }}
        fullPageSize={false}
        isOpen={openReplacePopup}
        onClose={handleReplaceClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={REPLACE_PROJECT_TEXT} />}
        actions={
          <Div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleReplace}
              styles={{
                root: { mr: "0.5rem", height: "30px", width: "80px" },
              }}
            >
              {YES_Text}
            </Button>

            <Button
              variant="primary"
              textCenter={true}
              onClick={handleReplaceClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {NO_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "130px",
            },
          }}
        ></Icon>
        <P textAlign={"center"}>{REPLACE}</P>
        <P textAlign={"center"}> {REPLACE_MESSAGE}</P>
      </Modal>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openInfoPopup}
        onClose={handleInfoClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={UNSUPPORTED_CHAR_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleInfoClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {OK_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "130px",
            },
          }}
        ></Icon>
        <P textAlign={"center"} style={{ marginLeft: "30px" }}>
          {SPECIAL_CHAR_TEXT}
        </P>
        <P textAlign={"center"} style={{ marginLeft: "25px" }}>
          {FILENAME_WARN_TEXT}
        </P>
      </Modal>
    </Div>
  );
};
