export const writeUsbData = async (
  usbDevice: SerialPort,
  writeData: Uint8Array
) => {
  try {
    if (!usbDevice) {
      console.error("The USB device is null or not properly initialized.");
      return;
    }
    const writer: WritableStreamDefaultWriter<any> =
      usbDevice.writable.getWriter();
    await writer.write(writeData);
    writer.releaseLock();
  } catch (e) {
    console.error("Error in global write:", e);
  }
};
