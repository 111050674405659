import * as React from "react";
import { LayoutContext } from "./layoutContext";

export const useLayout = () => {
  const context = React.useContext(LayoutContext);
  if (!context) {
    throw new Error(
      `useRefriglayout must be used within a RefrigLayoutProvider`
    );
  }
  return context;
};
