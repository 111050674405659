import { fetchData } from "../action/fetch";
import { DOWNLOAD_URL } from "../constants";
import { openFileRes } from "../types";

export const getDownloadFileData = async (
  projectDetails: openFileRes,
  fileType: string,
  isElectronApp: boolean,
  isOnline: boolean,
  replace: boolean,
  downloadName: string
) => {
  const downloadurl: string = DOWNLOAD_URL;
  try {
    const fileContent = await fetchData(downloadurl, {
      projectInfo: projectDetails,
      fileType: fileType,
      isElectronApp: isElectronApp,
      isOnline: isOnline,
      replaceProject: replace,
      downloadName: downloadName,
    });
    return fileContent?.data?.fileData;
  } catch (err) {
    console.error("Error in Api get ", err);
  }
};
