export const ALERT_MESSAGE = "Please enter modbus command";
export const TITLE_MESSAGE = "KPW-POC";
export const LOADER_MESSAGE = "KOOLPROG WEB";
export const COL1 = "col1";
export const COL2 = "col2";
export const COL3 = "col3";
export const COL4 = "col4";
export const COL5 = "col5";
export const COL6 = "col6";
export const COL7 = "col7";
export const NAME = "NAME";
export const LABEL = "LABEL";
export const DESCRIPTION = "DESCRIPTION";
export const MIN = "MIN";
export const MAX = "MAX";
export const DEFAULT = "DEFAULT";
export const VALUE = "VALUE";
export const SAMPLE_MESSAGE = "Welcome to KoolProg";
export const SETPARAMETER = "SetParameter";
export const CDF = "cdf";
export const OFFLINE = "Offline";
export const SELECTCODE = "SelectCode";
export const SETPARAMNAME = "Parameter Configuration";
export const CDFFILEFORMAT = ".cdf";
export const ZIPFILEFORMAT = ".zip";
export const Convertion = "blob";
export const SETDEFAULT = "Device Connection";
export const HOME_URL = "https://kpwserver-api.azurewebsites.net/deviceInfo";
export const CDF_URL = "https://kpwserver-api.azurewebsites.net/controller/cdf";
export const OFFLINE_URL = "https://kpwserver-api.azurewebsites.net/offline";
export const CONTROLLER_INFO_URL = "https://kpwserver-api.azurewebsites.net/controllerInfo";
export const NEW_PROJECT_URL = "https://kpwserver-api.azurewebsites.net/newProject";
export const SAVE_FILE_URL = "https://kpwserver-api.azurewebsites.net/saveFile";
export const SAVEAS_FILE_URL = "https://kpwserver-api.azurewebsites.net/saveAsFile";
export const OPEN_PROJECT_URL = "https://kpwserver-api.azurewebsites.net/openProject";
export const OPEN_FILE_DB_URL = "https://kpwserver-api.azurewebsites.net/openFileDb";
export const DOWNLOAD_URL = "https://kpwserver-api.azurewebsites.net/download";
export const UPLOAD_URL = "https://kpwserver-api.azurewebsites.net/upload";
export const READ_DEVICE_ADDRESS = "03";
export const WRITE_DEVICE_ADDRESS = "06";
export const DEVICE_SLAVE_ADDRESS = "0x00";
export const DEVICE_FIRST_REGISTER = `0x00`;
export const FIRSTBYTE = "0";
export const USB_RESPONSE_TIMEOUT = 3000;
export const USB_REQRES_TIMEOUT = 100;
export const BULK_READ_TIMEOUT = 150;
export const IDENTIFICATION_TIMEOUT = 1000;
export const WRITE_COMMAND_TIMEOUT = 1000;
export const MAX_CONVERSION_RETRY_COUNT = 3;
export const PORT_CONNECTED_STATUS = "success";
export const PORT_NOT_CONNECTED_STATUS = "error";
export const IDENTIFY_DEVICE_API = "2b";
export const READ_DEVICE_API = "03";
export enum PORT_CONFIG {
  BAUDRATE = 19200,
  PARITY = "even",
}
export const SELECT_CONTROLLER_TEXT = "Select Controller Model";
export const SELECT_CODE_NUMBER_TEXT = "Select Code Number";
export const CODE_NUMBER_TEXT = "Code Number";
export const SELECT_VERSION_TEXT = "Select Software Version";
export const VERSION_TEXT = "Software Version";
export const GIVE_FILENAME_TEXT = "Project Name  *";
export const MAX_CHAR_TEXT = "Project Name (Max 20 char)";
export const GIVE_DESCRIPTION_TEXT = "Project Description";
export const USE_WIZARD_TEXT = " Use a wizard for controller configuration";
export const MAX_CHARS_TEXT = "Project Description (Max 250 char)";
export const NEW_PROJECT_TEXT = "New Project";
export const BACK_TEXT = "Back";
export const NEXT_TEXT = "Next";
export const CANCEL_TEXT = "Cancel";
export const FINISH_TEXT = "Finish";
export const SHOW_ALL_TEXT = "Show All";
export const OK_TEXT = "Ok";
export const EXIT = "Do You want to Exit New Project ?";
export const EXIT_MESSAGE = "Your changes will get lost";
export const PROJECT_INFO = "Project Info";
export const SELECT_CONTROLLER = "Select Controller";
export const UI_TEXT = " UI";
export const NEWPROJECT = "New Project";
export const ALL_TEXT = "All";
export const NEW_PROJECT_PATH = "/NewProject";
export const MESSAGE_TYPE = "Warning";
export const MESSAGE_TEXT = "Please upload a Cdf file !!";
export const ERROR_MESSAGE_TEXT = "Server Not Connected";
export const AKCC_TEXT = "AK-CC";
export const ITEM_TYPE_TEXT = "G";
export const BASE64_TEXT = "base64";
export const IMAGE_BASE64 = "data:image/png;base64,";
export const REPLACE = "Project With the name already exist";
export const REPLACE_MESSAGE = "Do you want to replace it ?";
export const FILE_CREATED_MESSAGE = "New Project created";
export const FILE_EXIST_MESSAGE = "File Name already exist";
export const SAVE_MESSAGE = "File saved successfully";
export const REPLACED_MESSAGE = "File replaced successfully";
export const JSON_EXTENSION = ".json";
export const XML_EXTENSION = ".xml";
export const SAVEAS_TEXT = "Save As";
export const SAVE_TEXT = "Save";
export const FILE_NAME_TEXT = "File Name";
export const FILE_TYPE_TEXT = "File Type";
export const REPLACE_PROJECT_TEXT = "Replace Project";
export const YES_Text = "Yes";
export const NO_TEXT = "No";
export const OPEN_PROJECT_PATH = "/OpenProject";
export const OPEN_PROJECT_TEXT = "Open Project";
export const EKC_TEXT = "EKC";
export const EKC_NAME_TEXT = "EKC22x";
export const EKE_TEXT = "EKE";
export const EKE_NAME_TEXT = "EKE-100";
export const SUCCESS_TEXT = "Success";
export const OPEN_PROJECT = " Open File";
export const DOWNLOAD_TEXT = "Download";
export const DOWNLOADED_MESSAGE = "File downloaded successfully";
export const UPLOADED_MESSAGE = "File uploaded successfully";
export const UPLOAD_TEXT = "Upload";
export const REPLACE_TEXT = "Replace";
export const RENAME_TEXT = "Rename";
export const FILE_TYPE_JSON = "application/json";
export const FILE_TYPE_XML = "application/xml";
export const SELECT_PROJECT_TEXT = "Select Project";
export const SELECT_FILE_TEXT = "Select File Type";
export const REPLACE_TYPE = /\.(json|xml)$/i;
export const ENUM_IDX_VALUE = -1;
export const REMOVE_NUMBERS = /[0-9 -]+$/g;
export const FORMAT_SPACE = 2;
export const RENAME_FILE_TEXT = "Rename File";
export const PROJECT_EXIST_TEXT = "Project Exist";
export const SPECIAL_CHAR_TEXT = "& * | ; : , < > ' \" ? / \\";
export const FILENAME_WARN_TEXT = "These characters are not supported";
export const REGEX_SPECIALCHAR = /[&*|;:<>"?\/\\',]/;
export const INVALID_INPUT_MESSAGE = "Please enter parameter value within Min and Max range";
export const INVALID_VALUE_TEXT = "Invalid value";
export const UNSUPPORTED_CHAR_TEXT = "Unsupported Characters";
export const INFO_INTEGER_TEXT = "Please give integer value";
export const WAIT_TEXT = "Please Wait";
export const REGEX_UNSUPPORTED_INPUT = /^-?\d+(\.\d+)?$/;
export const DONE_TEXT = "Done";
export const DEVICE_NOT_CONNECTED = "USB Device not Connected or Disconnected";