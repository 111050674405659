import { Div } from "@danfoss/etui-system-elements";
import { TreeItem } from "@danfoss/etui-core";
import { Container, Row, Steps, StepProps } from "@danfoss/etui-core";
import { SplitPopup } from "../components/components/SplitView/SplitPopup";
import {
  CodeNumberDBResponse,
  stepData,
  cdfDataValue,
} from "../types/controllerData.types";
import { OfflineDevice } from "./OfflineDevice";
import { ConfigurationPage } from "./NewProjectConfigurationPage";
import {
  EKC_NAME_TEXT,
  EKC_TEXT,
  EKE_NAME_TEXT,
  EKE_TEXT,
  PROJECT_INFO,
  REMOVE_NUMBERS,
  SELECT_CONTROLLER,
  SHOW_ALL_TEXT,
  UI_TEXT,
} from "../constants";
import {
  offlineIconName,
  offlineIconVersion,
} from "../components/ParameterTreeView";
import { ControllerInfoPage } from "./ControllerInfoPage";

export interface stepContentData {
  [key: number]: JSX.Element;
}

export const PopupContent = ({
  useOnlyNumber,
  activeDataStep,
  allCdfData,
}: {
  useOnlyNumber: boolean;
  activeDataStep: number;
  allCdfData: cdfDataValue[];
}) => {
  const allVersionNames: string[] = [];
  allCdfData?.map((item: cdfDataValue) => {
    const elements: string[] = [];
    item?.cdfData?.device?.Header?.CodeNumbers?.map(
      (item_code: CodeNumberDBResponse) => {
        if (item_code?.Text?.includes(UI_TEXT)) {
          return elements?.push(item_code?.Text?.replace(UI_TEXT, ""));
        } else {
          return elements?.push(item_code?.Text);
        }
      }
    );
    allVersionNames?.push(...elements);
  });

  const removeDuplicates = (allVersionNames: string[]) => {
    return allVersionNames?.filter(
      (item: string, index: number) => allVersionNames?.indexOf(item) === index
    );
  };
  const uniqueVersionName = removeDuplicates(allVersionNames);

  const controllerName: string[] = [];
  allCdfData.map((item: cdfDataValue) => {
    const name = item?.cdfData?.device?.Header?.CodeNumbers[0]?.Text?.slice(
      0,
      5
    );
    const formatName: string = name?.replace(REMOVE_NUMBERS, " ");
    return controllerName?.push(formatName);
  });

  const uniqueControllerName = removeDuplicates(controllerName);
  const formatedControllerName = uniqueControllerName.map((name: string) => {
    if (name.trim() === EKC_TEXT) {
      return EKC_NAME_TEXT;
    } else if (name.trim() === EKE_TEXT) {
      return EKE_NAME_TEXT;
    } else {
      return name;
    }
  });
  const subItems: TreeItem[] = formatedControllerName.map((name, index) => ({
    id: 2 + index,
    name: name.trim(),
    glyph: offlineIconName[index].icon,
    items: uniqueVersionName
      .filter((version) => version.startsWith(name.trim().slice(0, 3)))
      .map((item, item_index) => ({
        id: 11 + index + item_index * uniqueControllerName.length,
        name: item,
        glyph: offlineIconVersion[index].icon,
      })),
  }));

  const modalTreeData: TreeItem[] = [
    {
      id: 1,
      name: SHOW_ALL_TEXT,
      items: subItems,
    },
  ];

  const stepContent: stepContentData = {
    1: OfflineDevice(modalTreeData),
    2: ConfigurationPage(allCdfData),
  };

  const steps: StepProps[] = [
    {
      title: SELECT_CONTROLLER,
      stepNumber: 1,
    },
    {
      title: PROJECT_INFO,
      stepNumber: 2,
    },
  ].map((item) =>
    useOnlyNumber ? { stepNumber: item.stepNumber } : { ...item }
  );

  return (
    <Div>
      <Steps
        activeStep={activeDataStep}
        {...{
          children: steps.map((step: stepData) => (
            <Steps.Step
              key={step.stepNumber}
              {...step}
              isCompleted={(step.stepNumber as number) < activeDataStep}
            />
          )),
        }}
      />
      <SplitPopup
        leftPane={
          <Div>
            <Container fluid={false}>
              <Row>
                <Div height={150}>{stepContent[activeDataStep]}</Div>
              </Row>
            </Container>
          </Div>
        }
        rightPane={
          <Div>
            <ControllerInfoPage />
          </Div>
        }
      ></SplitPopup>
    </Div>
  );
};
