import { fetchData } from "../action/fetch";
import { OPEN_FILE_DB_URL } from "../constants";
import { openFileRes } from "../types";

export const getOpenFileDbData = async (projectDetails: openFileRes) => {
  const openFileDburl: string = OPEN_FILE_DB_URL;
  try {
    const fileContent = await fetchData(openFileDburl, {
      projectInfo: projectDetails,
    });
    return fileContent?.data?.fileDetails;
  } catch (err) {
    console.error("Error in Api get ", err);
  }
};
