import { icons } from "@danfoss/etui-core";
import { treeViewIconsData } from "../../types";
export const iconsData: treeViewIconsData[] = [
  {
    icon: icons.COG,
    id: 1,
  },
  {
    icon: icons.COG,
    id: 2,
  },
  {
    icon: icons.REFRIG_COPY,
    id: 3,
  },
  {
    icon: icons.ALARM_URGENT,
    id: 4,
  },
  {
    icon: icons.REFRIGERATION,
    id: 5,
  },
  {
    icon: icons.CASE_FAN,
    id: 6,
  },
  {
    icon: icons.SUCTION_GROUP,
    id: 7,
  },
  {
    icon: icons.MISC,
    id: 8,
  },
  {
    icon: icons.ELECTRICS,
    id: 9,
  },
  {
    icon: icons.COPY,
    id: 10,
  },
  {
    icon: icons.NO_ACCESS,
    id: 11,
  },
  {
    icon: icons.AUDIT_TRAIL,
    id: 12,
  },
  {
    icon: icons.CALENDAR,
    id: 13,
  },
  {
    icon: icons.CLOCK,
    id: 14,
  },
  {
    icon: icons.CARWASH,
    id: 15,
  },
  {
    icon: icons.TILE_VIEW,
    id: 16,
  },
  {
    icon: icons.COMFORT,
    id: 17,
  },
  {
    icon: icons.CONDENSER,
    id: 18,
  },
  {
    icon: icons.CUBE,
    id: 19,
  },
  {
    icon: icons.DEFROST,
    id: 20,
  },
  {
    icon: icons.DIAGNOSTIC_CIRCLE,
    id: 21,
  },
  {
    icon: icons.DISCONNECTED_CLOUD,
    id: 22,
  },
  {
    icon: icons.ENVELOPE,
    id: 23,
  },
  {
    icon: icons.FROST,
    id: 24,
  },
  {
    icon: icons.HVAC,
    id: 25,
  },
  {
    icon: icons.HAMBURGER,
    id: 26,
  },
  {
    icon: icons.HOME,
    id: 27,
  },
  {
    icon: icons.HEATING,
    id: 28,
  },
  {
    icon: icons.HVAC_COPY,
    id: 29,
  },
  {
    icon: icons.LIGHTING_ZONE,
    id: 30,
  },
  {
    icon: icons.LIST_VIEW_BORDERED,
    id: 31,
  },
  {
    icon: icons.MISC_LEAK_SENSOR,
    id: 32,
  },
  {
    icon: icons.MISC_ON_OFF,
    id: 33,
  },
  {
    icon: icons.MISC_RELAY_OUTPUT,
    id: 34,
  },
  {
    icon: icons.PARAMETERS,
    id: 35,
  },
  {
    icon: icons.REFRIGERATION,
    id: 36,
  },
  {
    icon: icons.SETTINGS,
    id: 37,
  },
  {
    icon: icons.TILE_VIEW_BORDERED,
    id: 38,
  },
  {
    icon: icons.SOFTWARE_UPDATE,
    id: 39,
  },
];

export const offlineIconName: treeViewIconsData[] = [
  {
    icon: icons.COMFORT,
    id: 0,
  },
  {
    icon: icons.CASE_FAN,
    id: 1,
  },
  {
    icon: icons.REFRIGERATION,
    id: 2,
  },
  {
    icon: icons.REFRIG_COPY,
    id: 3,
  },
  {
    icon: icons.SOFTWARE_UPDATE,
    id: 4,
  },
  {
    icon: icons.DEFROST,
    id: 5,
  },
];

export const offlineIconVersion: treeViewIconsData[] = [
  {
    icon: icons.HVAC_COPY,
    id: 0,
  },
  {
    icon: icons.SUCTION_GROUP,
    id: 1,
  },
  {
    icon: icons.MISC,
    id: 2,
  },
  {
    icon: icons.ELECTRICS,
    id: 3,
  },
  {
    icon: icons.COPY,
    id: 4,
  },
  {
    icon: icons.HVAC,
    id: 5,
  },
];
