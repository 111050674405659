import React from "react";
import {
  SelectInputOption,
  SelectedValueType,
  TreeItem,
  TreeView,
} from "@danfoss/etui-core";
import {
  TreeViewsProps,
  Group,
  FinalDataValue,
  updatedReadData,
  updatedItem,
  Item,
} from "../../types/controllerData.types";
import { Div } from "@danfoss/etui-system-elements";
import { MainTreeItem } from "../components/components/MainTreeItem";
import { iconsData } from "./iconsData";
import { readComplements } from "../..//utils/readComplement";
import { useLayout } from "../context/layoutConfig";
import {
  decimalValues,
  finalData,
  getMatchedValue,
  getSelectionOptions,
  updatedValues,
} from "../../utils/TreeData";
import { ParameterType } from "../../types/controllerData.types";
import { ALL_TEXT, ITEM_TYPE_TEXT } from "../../constants";

const ParameterTreeView = ({
  treeData,
  setSelectedParameter,
}: TreeViewsProps) => {
  const [menuTreeData, setMenuTreeData] = React.useState<TreeItem[]>([]);
  const { selectedItem, setSelectedParam } = useLayout();
  const [showDefaultMenu, setShowDefaultMenu] = React.useState<boolean>(false);
  const [openSections, setOpenSections] = React.useState<SelectedValueType[]>(
    []
  );
  const [currentSelectedRow, setCurrentSelectedRow] =
    React.useState<TreeItem>();

  React.useEffect(() => {
    setShowDefaultMenu(true);
  }, []);
  React.useEffect(() => {
    if (treeData.pnuValues) {
      processMenuTree();
    }
    if (selectedItem) {
      setCurrentSelectedRow(selectedItem);
      setSelectedParameter(selectedItem);
    }
  }, [treeData.pnuValues, selectedItem]);
  React.useEffect(() => {
    if (showDefaultMenu && menuTreeData.length) {
      let sectionsIds = [];
      sectionsIds.push(menuTreeData.at(0)?.id);
      sectionsIds.push(menuTreeData.at(0)?.items?.at(0)?.id);
      sectionsIds.push(menuTreeData.at(0)?.items?.at(0)?.items?.at(0)?.id);
      const menuExpand: TreeItem | any = menuTreeData
        .at(0)
        ?.items?.at(0)
        ?.items?.at(0);
      setCurrentSelectedRow(menuExpand);
      setSelectedParameter(menuExpand);
      setOpenSections(sectionsIds);
    }
  }, [menuTreeData?.length]);

  const handleOpenSectionChange = (
    sectionsOpenUpdated: SelectedValueType[]
  ) => {
    setOpenSections(sectionsOpenUpdated);
  };
  const processMenuTree = () => {

    const allGroup = treeData?.groups;
    const firstItem = allGroup?.[0]?.Text;
    const processItems = (items: Item[]): TreeItem[] => {
      if (!items) {
        return [];
      }

      const result: TreeItem[] = [];

      items.forEach((item) => {
        if (item.ItemType === ITEM_TYPE_TEXT) {
          const nestedItems = processItems(treeData?.groups[item.Index]?.Items);

          const processedItem: TreeItem = {
            id: treeData?.groups[item.Index]?.Idx,
            name: treeData?.groups[item.Index]?.Text,
            glyph: iconsData[item.Index]?.icon,
            items: nestedItems,
          };

          result.push(processedItem);
        }
      });

      return result;
    };

    const firstGroupItems = allGroup?.[0]?.Items;

    const secondAllItems = processItems(firstGroupItems);

    const treeViewData: TreeItem[] = [
      {
        id: 41,
        name: ALL_TEXT,
        items: [
          {
            id: 42,
            name: firstItem,
            items: secondAllItems,
          },
        ],
      },
    ];
    setMenuTreeData(treeViewData);
    collectSelectInputValues(treeViewData);
  };
  
  const getParamValue = (paramObj: ParameterType) => {
    const allvalues: FinalDataValue[] | any = getMatchedValue(
      paramObj?.EnumIdx,
      paramObj?.Min,
      treeData
    );
    if (paramObj?.EnumIdx >= 0 && allvalues?.length) {
      const options: SelectInputOption[] = getSelectionOptions(allvalues);
      const defaultValue: number = updatedValues(paramObj, treeData);

      options?.map((item: SelectInputOption) => {
        return item.value;
      });
      const complement: number = readComplements(defaultValue);
      const allOptions: SelectInputOption = options[complement];

      const selectedOptions: SelectInputOption | undefined = options.find(
        (option: SelectInputOption) => {
          const value = Number(option?.value);
          return value === complement;
        }
      );
      const result: SelectInputOption = finalData(
        options,
        selectedOptions,
        allOptions
      );
      return result;
    }
  };
  const collectSelectInputValues = (items: TreeItem[]) => {
    const collectedparamData: updatedReadData[] = [];
    const processItem = (item: TreeItem) => {
      const groupsData: Group[] = treeData?.groups;
      const result = groupsData.find((group) => group?.Idx === item?.id);
      const dataItems = result?.Items;
      dataItems?.forEach((item: updatedItem) => {
        const paramValuesObj = {
          key: item?.Index,
          paramValue:
            getParamValue(treeData?.parameters[item?.Index]) ||
            decimalValues(
              updatedValues(treeData?.parameters[item?.Index], treeData),
              item?.Index,
              treeData
            ),
        };
        collectedparamData.push(paramValuesObj);
      });

      if (item.items) {
        item.items.forEach(processItem);
      }
    };
    items.forEach(processItem);
    setSelectedParam(collectedparamData);
  };
  return (
    <Div>
      <TreeView
        data={menuTreeData}
        onRowSelect={setSelectedParameter}
        onOpenSectionChange={handleOpenSectionChange}
        openSections={openSections}
        selectedRow={currentSelectedRow}
        styles={{
          root: {
            fontWeight: 700,
          },
          row: {
            root: {
              fontWeight: 700,
            },
          },
        }}
        rowTemplate={(props: any) => (
          <MainTreeItem key={props.item.name} {...props} />
        )}
      />
    </Div>
  );
};

export default ParameterTreeView;
