import { NewProjectPage } from "./NewProjectModalPage";
import { OpenProject } from "./OpenProject";
import { Div } from "@danfoss/etui-system-elements";

export const OfflineProject = () => {
  return (
    <Div
      style={{
        marginTop: "100px",
      }}
    >
      <Div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Div
          style={{
            display: "flex",
            background: "#D8D8D9",
            width: "350px",
            height: "200px",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #969696",
          }}
        >
          <NewProjectPage />
          <OpenProject />
        </Div>
      </Div>
    </Div>
  );
};
