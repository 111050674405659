import {
  Button,
  Form,
  H6,
  Icon,
  Input,
  List,
  Modal,
  SelectInput,
  SelectInputOption,
  icons,
} from "@danfoss/etui-core";
import { Div, P } from "@danfoss/etui-system-elements";
import { eventData, openFileRes } from "../types";
import { getOpenFileDbData } from "../Services/openFileDbApi";
import { useEffect, useState } from "react";
import {
  DOWNLOAD_TEXT,
  SUCCESS_TEXT,
  FILE_TYPE_JSON,
  FILE_TYPE_XML,
  DOWNLOADED_MESSAGE,
  JSON_EXTENSION,
  SELECT_FILE_TEXT,
  SELECT_PROJECT_TEXT,
  XML_EXTENSION,
  REPLACE_TYPE,
  FORMAT_SPACE,
  FILE_EXIST_MESSAGE,
  PROJECT_EXIST_TEXT,
  RENAME_TEXT,
  REPLACE,
  REPLACE_TEXT,
  REPLACED_MESSAGE,
  RENAME_FILE_TEXT,
  CANCEL_TEXT,
  FILE_NAME_TEXT,
} from "../constants";
import { getDownloadFileData } from "../Services/downloadApi";
import { useLayout } from "../components/context/layoutConfig";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import { saveAs } from "file-saver";

export const DownloadPopup = () => {
  const [openFilePopup, setOpenFilePopup] = useState(false);
  const [fileName, setFileName] = useState<openFileRes[]>([]);
  const [selectedProject, setSelectedProject] = useState<openFileRes>(
    {} as openFileRes
  );
  const [selectedType, setSelectedType] = useState<string>(JSON_EXTENSION);
  const { isElectronApp, isOnline } = useLayout();
  const [blob, setBlob] = useState<Blob>();
  const [downloadName, setDownloadName] = useState<string>("");
  const [openDownloadPopup, setOpenDownloadPopup] = useState<boolean>(false);
  const [downloadFileName, setDownloadFileName] = useState<string>("");
  const [openRenamePopup, setOpenRenamePopup] = useState<boolean>(false);

  useEffect(() => {
    if (blob && downloadName) {
      saveAs(blob, downloadName);
    }
  }, [blob, downloadName]);
  useEffect(() => {
    setSelectedType(JSON_EXTENSION);
  }, [openFilePopup]);

  const handleDownloadPopup = async () => {
    const allFilesDb: openFileRes[] = await getOpenFileDbData(
      {} as openFileRes
    );

    if (allFilesDb) {
      const Name: openFileRes[] = [];
      setOpenFilePopup(true);
      allFilesDb.forEach((file) => {
        return Name.push(file);
      });
      setFileName(Name);
    }
  };
  const handleClose = () => {
    setOpenFilePopup(false);
  };
  const handleItemClick = async (project: openFileRes) => {
    setSelectedProject(project);
  };

  const handelFileType = (e: SelectInputOption) => {
    setSelectedType(e.value);
  };

  const handleFileDownload = async () => {
    const selectedFileData = await getDownloadFileData(
      selectedProject,
      selectedType,
      isElectronApp,
      isOnline,
      false,
      ""
    );
    if (selectedFileData && isElectronApp && isOnline) {
      if (selectedFileData === DOWNLOADED_MESSAGE) {
        Notification.success({
          message: SUCCESS_TEXT,
          description: DOWNLOADED_MESSAGE,
          duration: 3,
          theme: defaultTheme,
        });
        setOpenFilePopup(false);
      } else if (selectedFileData === FILE_EXIST_MESSAGE) {
        setOpenDownloadPopup(true);
      }
    } else {
      if (selectedType === JSON_EXTENSION) {
        setBlob(
          new Blob([JSON.stringify(selectedFileData, null, FORMAT_SPACE)], {
            type: FILE_TYPE_JSON,
          })
        );
        setDownloadName(
          selectedProject.projectName.replace(REPLACE_TYPE, "") + JSON_EXTENSION
        );
      } else if (selectedType === XML_EXTENSION) {
        const xmlBlob: Blob = new Blob([selectedFileData], {
          type: FILE_TYPE_XML,
        });
        setBlob(xmlBlob);
        const xmlFileName: string =
          selectedProject.projectName.replace(REPLACE_TYPE, "") + XML_EXTENSION;
        setDownloadName(xmlFileName);
      } else {
        console.error("Unsupported file type");
      }
    }
  };

  const handleReplaceClose = async () => {
    setOpenDownloadPopup(false);
  };

  const handleReplace = async () => {
    const selectedFileData = await getDownloadFileData(
      selectedProject,
      selectedType,
      isElectronApp,
      isOnline,
      true,
      ""
    );
    setOpenDownloadPopup(false);
    if (selectedFileData === REPLACED_MESSAGE) {
      Notification.success({
        message: SUCCESS_TEXT,
        description: REPLACED_MESSAGE,
        duration: 3,
        theme: defaultTheme,
      });
    }
  };
  const handleFileName = (e: eventData) => {
    setDownloadFileName(e?.target.value);
  };
  const handleRenameClose = () => {
    setOpenRenamePopup(false);
  };
  const handleRename = () => {
    setOpenRenamePopup(true);
  };
  const handleRenameFile = async () => {
    setOpenRenamePopup(false);
    setOpenDownloadPopup(false);

    const selectedFileData = await getDownloadFileData(
      selectedProject,
      selectedType,
      isElectronApp,
      isOnline,
      false,
      downloadFileName
    );
    if (selectedFileData && isElectronApp && isOnline) {
      if (selectedFileData === DOWNLOADED_MESSAGE) {
        Notification.success({
          message: SUCCESS_TEXT,
          description: DOWNLOADED_MESSAGE,
          duration: 3,
          theme: defaultTheme,
        });
        setOpenFilePopup(false);
      } else if (selectedFileData === FILE_EXIST_MESSAGE) {
        setOpenFilePopup(true);
      }
    }
  };

  return (
    <Div>
      <Button
        small={true}
        onClick={handleDownloadPopup}
        iconProps={{ glyph: icons.DOWNLOAD }}
        styles={{
          root: {
            height: "35px",
            top: "13.5px",
            color: "black !important",
            left: "602px",
          },
        }}
      >
        {DOWNLOAD_TEXT}
      </Button>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "395px", width: "410px" } }}
        fullPageSize={false}
        isOpen={openFilePopup}
        onClose={handleClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={SELECT_PROJECT_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleFileDownload}
              styles={{
                root: { mr: "0.5rem", height: "35px" },
              }}
            >
              {DOWNLOAD_TEXT}
            </Button>
          </Div>
        }
      >
        <Div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Div style={{ flexShrink: 0 }}>
            <SelectInput
              name={SELECT_FILE_TEXT}
              label={SELECT_FILE_TEXT}
              size="small"
              options={[
                { value: JSON_EXTENSION, label: JSON_EXTENSION },
                { value: XML_EXTENSION, label: XML_EXTENSION },
              ]}
              defaultValue={{ value: JSON_EXTENSION, label: JSON_EXTENSION }}
              onChange={handelFileType}
              styles={{ root: { pr: "100px", pl: "100px", pt: "10px" } }}
            />
          </Div>
          <Div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              marginTop: "10px",
              height: "300px",
            }}
          >
            <List
              dataSource={fileName}
              mode="default"
              styles={{ root: { pl: "10px" } }}
              renderItem={(item) => (
                <Button
                  onClick={() => handleItemClick(item)}
                  style={{
                    height: "50px",
                    width: "350px",
                    textAlign: "center",
                    borderBottom: "0.5px solid #dee2e6",
                    backgroundColor:
                      selectedProject === item ? "#ECECEC" : "white",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f8f9fa")
                  }
                  onMouseLeave={(e) => {
                    if (selectedProject === item) {
                      e.currentTarget.style.backgroundColor = "#ECECEC";
                    } else {
                      e.currentTarget.style.backgroundColor = "white";
                    }
                  }}
                >
                  <List.Item.Meta
                    title={`${item?.projectName.replace(REPLACE_TYPE, "")}`}
                  />
                </Button>
              )}
            />
          </Div>
        </Div>
      </Modal>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openDownloadPopup}
        onClose={handleReplaceClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={PROJECT_EXIST_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleReplace}
              styles={{
                root: { mr: "0.5rem", height: "30px", width: "80px" },
              }}
            >
              {REPLACE_TEXT}
            </Button>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleRename}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {RENAME_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "110px",
            },
          }}
        ></Icon>
        <P textAlign={"center"}>{REPLACE}</P>
      </Modal>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openRenamePopup}
        onClose={handleRenameClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={RENAME_FILE_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleRenameFile}
              styles={{
                root: { mr: "0.5rem", height: "30px", width: "90px" },
              }}
            >
              {RENAME_TEXT}
            </Button>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleRenameClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {CANCEL_TEXT}
            </Button>
          </Div>
        }
      >
        <Form styles={{ root: { pl: "20px", pr: "20px", pt: "10px" } }}>
          <H6>{FILE_NAME_TEXT}</H6>
          <Input
            type="text"
            size="small"
            placeholder={FILE_NAME_TEXT}
            onChange={(e) => {
              handleFileName(e);
            }}
          ></Input>
        </Form>
      </Modal>
    </Div>
  );
};
