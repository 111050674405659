import { Div, P } from "@danfoss/etui-system-elements";
import {
  Button,
  List,
  Modal,
  SelectInputOption,
  icons,
} from "@danfoss/etui-core";
import React, { useRef, useState } from "react";
import { controllerInfoData, openFileRes, openProjectRes } from "../types";
import { getOpenProjectData } from "../Services/openProjectApi";
import { useNavigate } from "react-router-dom";
import {
  EKC_NAME_TEXT,
  EKC_TEXT,
  EKE_NAME_TEXT,
  EKE_TEXT,
  OPEN_PROJECT,
  OPEN_PROJECT_PATH,
  OPEN_PROJECT_TEXT,
  SELECT_PROJECT_TEXT,
} from "../constants";
import { useLayout } from "../components/context/layoutConfig";
import { getOpenFileDbData } from "../Services/openFileDbApi";

export const OpenProject = () => {
  const {
    setSelectedDeviceName,
    setSelectedSWVersion,
    setSelectedCodeNumber,
    setControllerImg,
    setNewProjectName,
    controllerInfoData,
    setSelectedCdfFile,
    setOpenProjectData,
    setOpenProject,
    setFileExtension,
    setOpenedProjectName,
    setControllerFamily,
    isElectronApp,
    setUploadNameWithType,
  } = useLayout();

  const [openFilePopup, setOpenFilePopup] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState<openFileRes[]>([]);
  const [selectedProjectDetails, setSelectedProjectDetails] =
    useState<openFileRes>({} as openFileRes);

  const deviceFamilyName = (controllerName: string) => {
    const name: string = controllerName?.slice(0, 3);
    return name === EKC_TEXT
      ? EKC_NAME_TEXT
      : name === EKE_TEXT
      ? EKE_NAME_TEXT
      : name;
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const NewProjectRoute = () => {
    let path: string = OPEN_PROJECT_PATH;
    navigate(path);
  };
  const handleClickOpen = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (isElectronApp) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      const openfileDbRes: openFileRes[] = await getOpenFileDbData(
        {} as openFileRes
      );
      if (openfileDbRes) {
        setFilteredFiles(openfileDbRes);
        setOpenFilePopup(true);
      }
    }
  };
  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event?.target?.files;
    if (fileList) {
      const fileName: string = fileList[0]?.name;
      setUploadNameWithType(fileName);
      const openResponse: openProjectRes = await getOpenProjectData(fileName);
      if (openResponse) {
        setNewProjectName(openResponse?.fileData?.projectName);
        const codeNumbers: string =
          openResponse?.fileData?.projectData?.CNF?.ProductSpecificInfo
            ?.CodeNumber;
        const codeNumber: SelectInputOption = {
          label: `${codeNumbers}`,
          value: `${codeNumbers}`,
        };
        setSelectedCodeNumber(codeNumber);
        const version: string =
          openResponse?.fileData?.projectData?.CNF?.ProductSpecificInfo
            ?.SWVersion;
        const softwareVersion: SelectInputOption = {
          label: `${version}`,
          value: `${version}`,
        };
        setSelectedSWVersion(softwareVersion);
        setSelectedDeviceName(openResponse?.fileData?.projectData?.CNF?.Name1);

        const dataItem = controllerInfoData?.find(
          (item: controllerInfoData) =>
            item?.name === openResponse?.fileData?.projectData?.CNF?.Name1
        ) as controllerInfoData;
        if (dataItem) {
          setControllerImg(dataItem?.productImage);
        }
        setSelectedCdfFile(openResponse?.matchedCdfData);
        setOpenProjectData(openResponse?.openProjectData);
        setOpenProject(true);
        setFileExtension(openResponse?.fileType);
        const fileWithName: string =
          openResponse?.fileData?.projectName + openResponse?.fileType;
        setOpenedProjectName(fileWithName);
        const controllerName: string = deviceFamilyName(
          openResponse?.fileData?.projectData?.CNF?.Name0
        );
        setControllerFamily(controllerName);
        NewProjectRoute();
      }
    }
  };

  const handleClose = () => {
    setOpenFilePopup(false);
  };

  const handleItemClick = async (projectDetails: openFileRes) => {
    setSelectedProjectDetails(projectDetails);
  };

  const handleFileOpen = async () => {
    const openDbResponse = await getOpenFileDbData(selectedProjectDetails);
    if (openDbResponse) {
      setNewProjectName(
        openDbResponse?.fileData?.projectName.replace(/\.(json|xml)$/i, "")
      );
      const codeNumbers: string =
        openDbResponse?.fileData?.projectData?.CNF?.ProductSpecificInfo
          ?.CodeNumber;
      const codeNumber: SelectInputOption = {
        label: `${codeNumbers}`,
        value: `${codeNumbers}`,
      };
      setSelectedCodeNumber(codeNumber);
      const version =
        openDbResponse?.fileData?.projectData?.CNF?.ProductSpecificInfo
          ?.SWVersion;
      const softwareVersion: SelectInputOption = {
        label: `${version}`,
        value: `${version}`,
      };
      setSelectedSWVersion(softwareVersion);
      setSelectedDeviceName(openDbResponse?.fileData?.projectData?.CNF?.Name1);

      const dataItem = controllerInfoData?.find(
        (item: controllerInfoData) =>
          item?.name === openDbResponse?.fileData?.projectData?.CNF?.Name1
      ) as controllerInfoData;
      if (dataItem) {
        setControllerImg(dataItem?.productImage);
      }
      setSelectedCdfFile(openDbResponse?.matchedCdfData);
      setOpenProjectData(openDbResponse?.openProjectData);
      setOpenProject(true);
      setFileExtension(openDbResponse?.fileType);
      const fileWithName: string = openDbResponse?.fileData?.projectName;
      setOpenedProjectName(fileWithName);
      const controllerName: string = deviceFamilyName(
        openDbResponse?.fileData?.projectData?.CNF?.Name0
      );
      if (controllerName) {
        setControllerFamily(controllerName);
      }
      NewProjectRoute();
    }
  };

  return (
    <Div>
      <Div
        style={{
          background: "#303844",
          height: "90px",
          width: "100px",
          marginLeft: "15px",
        }}
      >
        <Button
          variant="primary"
          iconProps={{ glyph: icons.LIST_VIEW_BORDERED, size: 48 }}
          onClick={handleClickOpen}
          styles={{
            root: {
              height: "50px",
              width: "80px",
              background: "#34495E",
              alignItems: "center",
              mt: "10px",
              ml: "10px",
              mr: "10px",
            },
          }}
        ></Button>
        <P
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "5px",
          }}
        >
          {OPEN_PROJECT_TEXT}
        </P>
      </Div>
      <input
        type="file"
        ref={fileInputRef}
        accept=".xml , .json"
        style={{ display: "none" }}
        onChange={handleFileSelect}
        multiple={false}
      />
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "395px", width: "410px" } }}
        fullPageSize={false}
        isOpen={openFilePopup}
        onClose={handleClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={SELECT_PROJECT_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleFileOpen}
              styles={{
                root: { mr: "0.5rem", height: "35px" },
              }}
            >
              {OPEN_PROJECT}
            </Button>
          </Div>
        }
      >
        <Div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Div style={{ flexGrow: 1, overflowY: "auto", marginTop: "10px" }}>
            <List
              dataSource={filteredFiles}
              mode="default"
              styles={{ root: { pl: "10px" } }}
              renderItem={(item) => (
                <Button
                  onClick={() => handleItemClick(item)}
                  style={{
                    height: "50px",
                    width: "350px",
                    textAlign: "center",
                    borderBottom: "0.5px solid #dee2e6",
                    backgroundColor:
                      selectedProjectDetails === item ? "#ECECEC" : "white",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f8f9fa")
                  }
                  onMouseLeave={(e) => {
                    if (selectedProjectDetails === item) {
                      e.currentTarget.style.backgroundColor = "#ECECEC";
                    } else {
                      e.currentTarget.style.backgroundColor = "white";
                    }
                  }}
                >
                  <List.Item.Meta
                    title={`${item?.projectName.replace(/\.(json|xml)$/i, "")}`}
                  />
                </Button>
              )}
            />
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};
