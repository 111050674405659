import { fetchData } from "../action/fetch";
import { SAVEAS_FILE_URL } from "../constants";
import { saveAsData } from "../types/controllerData.types";

export const getSaveAsProjectData = async (
  projectData: saveAsData,
  replaceProject: boolean,
  openProject: boolean,
  openedProjectName: string,
  electronApp: boolean
) => {
  try {
    const saveAsProjecturl: string =
      process.env.APPSETTING_CDF_URL || SAVEAS_FILE_URL;
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      mode: "no-cors",
    };
    const resData = await fetchData(saveAsProjecturl, {
      saveAsData: projectData,
      replaceProject: replaceProject,
      openedProject: openProject,
      openedProjectName: openedProjectName,
      isElectronApp: electronApp,
      headers,
    });
    return resData?.data?.message;
  } catch (error) {
    console.error("Error new Project save As API response:", error);
  }
};
