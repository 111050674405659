import { Button, Icon, Modal, icons } from "@danfoss/etui-core";
import { CANCEL_TEXT, EXIT, EXIT_MESSAGE, OK_TEXT } from "../constants";
import { Div, P } from "@danfoss/etui-system-elements";
import { useLayout } from "../components/context/layoutConfig";

export const CancelPopup = () => {
  const {
    openExitPopup,
    setOpenPopup,
    setSelectedDeviceName,
    setdisableNext,
    activeStep,
    setActiveStep,
    setOpenExitPopup,
  } = useLayout();

  const handleExitOpen = () => {
    if (activeStep === 2) {
      setOpenExitPopup(true);
    } else {
      setOpenPopup(false);
      setdisableNext(true);
    }
  };
  const handleExitClose = () => {
    setOpenExitPopup(false);
  };
  const handleClose = () => {
    handleExitClose();
    setOpenPopup(false);
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    }
    setSelectedDeviceName("");
    setdisableNext(true);
  };

  return (
    <Div>
      <Button
        variant="primary"
        textCenter={true}
        onClick={handleExitOpen}
        styles={{
          root: { height: "30px" },
        }}
      >
        {CANCEL_TEXT}
      </Button>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openExitPopup}
        onClose={handleExitClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title="Exit New Project" />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleExitClose}
              styles={{
                root: { mr: "0.5rem", width: "80px", height: "30px" },
              }}
            >
              {CANCEL_TEXT}
            </Button>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {OK_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "110px",
            },
          }}
        ></Icon>
        <P textAlign={"center"}>{EXIT}</P>
        <P textAlign={"center"}> {EXIT_MESSAGE}</P>
      </Modal>
    </Div>
  );
};
