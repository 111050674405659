import * as React from 'react';
import { Split } from '../Split';

export interface SplitViewProps {
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
}

function SplitView({
  leftPane,
  rightPane,
}: SplitViewProps) {

  const renderLeftPane = () => {
    const width =  ['100%', '25%', '20%'];
    return (
      <Split.Pane
      borderTop={'1px solid'}
        width={width}
        overflow="hidden"
        borderRight={`1px solid `}
        borderColor= "#EBEBEB"
        bg="white"
      >
        {leftPane}
      </Split.Pane>
    );
  };

  const renderRightPane = () => {
    const width =  ['100%', '75%', '80%'];
    return <Split.Pane width={width} borderTop={'1px solid'} borderColor= "#EBEBEB">{rightPane}</Split.Pane>;
  };

  return (
    <Split>
      { renderLeftPane()}
      { renderRightPane()}
    </Split>
  );
}

export { SplitView };
