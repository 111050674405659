import { fetchCdfData } from "../action/fetch";
import { OFFLINE_URL } from "../constants";

export const getOfflineData = async () => {
  try {
    const cdfContent = await fetchCdfData(OFFLINE_URL);
    return cdfContent?.data?.finalOfflineData;
  } catch (err) {
    console.error("Error in Api get ", err);
  }
};
