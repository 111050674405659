import { fetchCdfData } from "../action/fetch";
import { CONTROLLER_INFO_URL } from "../constants";

export const getControllerInfo = async () => {
  try {
    const controllerInfoResponse = await fetchCdfData(CONTROLLER_INFO_URL);
    const finalcontrollerInfo = controllerInfoResponse?.data?.info;
    return finalcontrollerInfo;
  } catch (err) {
    console.error("Error in controllerInfo Api get ", err);
  }
};
