import { fetchData } from "../action/fetch";
import { UPLOAD_URL } from "../constants";
import { newProjectData } from "../types";

export const getUploadData = async (
  Name: string,
  replace: boolean,
  rename: boolean,
  newName: string,
  isElectron: boolean,
  uploadData: string | newProjectData
) => {
  const uploadurl: string = UPLOAD_URL;
  try {
    const projectContent = await fetchData(uploadurl, {
      projectName: Name,
      replaceProject: replace,
      renameProject: rename,
      rename: newName,
      isElectron: isElectron,
      uploadData: uploadData,
    });
    return projectContent?.data.message;
  } catch (err) {
    console.error("Error in Api get ", err);
  }
};
