import ParameterTreeView from "../components/ParameterTreeView/ParameterTreeViews";
import {
  EngUnitTypes,
  FinalDataValue,
  Group,
  MainData,
  Paramdatas,
  ParameterTableData,
  ParametersData,
  finalReadValue,
  finalWriteData,
  selectInputData,
  updatedItem,
  updatedReadData,
} from "../types/controllerData.types";
import { useEffect, useState } from "react";
import { Div } from "@danfoss/etui-system-elements";
import TreeTable from "../components/ParameterTreeView/TreeTable";
import {
  CommandBar,
  Icon,
  InputAddon,
  SelectInput,
  SelectInputOption,
  TextInput,
  TreeItem,
  icons,
  Notification,
  Spinner,
  SpinnerSize,
} from "@danfoss/etui-core";
import { readComplements } from "../utils/readComplement";
import { ParameterType } from "../types/controllerData.types";
import { defaultTheme } from "@danfoss/etui-themes";
import { SplitView } from "../components/components/SplitView";
import { useLayout } from "../components/context/layoutConfig";
import React from "react";
import {
  decimalValues,
  finalData,
  formattedMaxMinValues,
  getDefaultData,
  getMatchedValue,
  getSelectionOptions,
  updatedValues,
} from "../utils/TreeData";
import { writeComplements } from "../utils/writeComplement";
import { dataWrite } from "../utils/writeRegister";
import { IMAGE_BASE64 } from "../constants";

const View = ({ data }: { data: MainData }) => {
  const [treeviewData, settreeviewData] = useState<ParametersData>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedItem,
    setSelectedItem,
    selectedparam,
    setSelectedParam,
    selectedAddress,
    onlineControllerImg,
  } = useLayout();
  const [writeCompleted, setWriteCompleted] = useState<boolean>(false);
  const [writeData, setWriteData] = useState<updatedReadData>({
    key: 0,
    paramValue: "",
  });
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [writeStatus, setWriteStatus] = useState<string | null>(null);
  const [showIcon, setShowIcon] = useState(false);
  const treeData: MainData = data;
  const connection: SerialPort = treeData.usbConnection;

  React.useEffect(() => {
    if ((treeData?.pnuValues).length > 0) {
      setIsLoading(false);
    }
  }, [treeData?.pnuValues]);

  const isDefaultData = (
    data: number,
    paramObj: ParameterType,
    index: number
  ) => {
    const updatedDecimalData: string = updateddefaultValues(paramObj, index);

    const formattedData: string = decimalValues(data, index, treeData);

    if (formattedData !== updatedDecimalData) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnParamChange = (value: string, index: number) => {
    const updatedParamValues: updatedReadData[] = [...selectedparam];
    const paramObject: updatedReadData | undefined = updatedParamValues?.find(
      (paramValue: updatedReadData) => paramValue.key === index
    );
    if (paramObject) {
      paramObject.paramValue = value;
    } else {
      updatedParamValues.push({ key: index, paramValue: value });
    }
    setSelectedParam(updatedParamValues);
  };
  useEffect(() => {
    if (selectedItem) {
      handleRowSelect(selectedItem);
    }
  }, [selectedparam, selectedItem, treeData, loadingIndex]);

  const handleSelectedParam = async (
    e: selectInputData,
    paramObj: ParameterType,
    index: number
  ) => {
    setWriteStatus(null);
    const updatedWrite: updatedReadData = {
      key: index,
      paramValue: e,
    };
    setLoadingIndex(index);
    setWriteData(updatedWrite);
    const updatedParamValues: updatedReadData[] = [...selectedparam];
    const paramObject = updatedParamValues.find(
      (paramValue: updatedReadData) => paramValue.key === index
    );
    if (paramObject) {
      paramObject.paramValue = e;
    } else {
      updatedParamValues.push({ key: index, paramValue: e });
    }
    setSelectedParam(updatedParamValues);

    const finalObj: finalWriteData = {
      vid: paramObj.Vid,
      value: e?.value,
      decimal: paramObj.Decimals,
    };

    const vidData: number = finalObj?.vid;
    const vidValue: number = writeComplements(finalObj?.value);
    const vidDecimal: number = finalObj?.decimal;
    const finalData: finalReadValue = await dataWrite(
      vidData,
      vidValue,
      vidDecimal,
      connection,
      selectedAddress
    );
    console.log("write combo value", finalData);
    if (finalData) {
      setWriteCompleted(true);
    }
  };
  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement, Element>,
    index: number,
    paramObj: ParameterType
  ) => {
    console.log("in handleblur");
    setWriteStatus(null);
    setLoadingIndex(index);
    await syncAndWriteData(e, index, paramObj);
  };

  const syncAndWriteData = async (
    e: React.FocusEvent<HTMLInputElement, Element>,
    index: number,
    paramObj: ParameterType
  ) => {
    let { value, accept } = e.target;
    const updatedWrite: updatedReadData = {
      key: Number(accept),
      paramValue: value,
    };
    setWriteData(updatedWrite);
    const min: number = treeData?.parameters[index].Min;
    const max: number = treeData?.parameters[index].Max;
    if (parseFloat(value) < min || parseFloat(value) > max) {
      const matchingItem: any = treeData.pnuValues.find(
        (item: { pnu: number }) => item.pnu === paramObj.Vid
      );
      const finalDecimal: string = decimalValues(
        matchingItem.value,
        index,
        treeData
      );

      const selectedDatas: string = (selectedparam.find((obj: Paramdatas) => {
        return obj.key.toString() === accept;
      }).paramValue = finalDecimal);

      const updatedParamValues: updatedReadData[] = [...selectedparam];
      const paramObject: updatedReadData = updatedParamValues.find(
        (paramValue: updatedReadData) => paramValue.key === index
      ) as updatedReadData;
      if (paramObject) {
        paramObject.paramValue = selectedDatas;
      } else {
        updatedParamValues.push({ key: index, paramValue: selectedDatas });
      }
      setSelectedParam(updatedParamValues);

      Notification.error({
        message: "Error",
        description: "Entered value is outside the valid range",
        duration: 3,
        theme: defaultTheme,
      });
    } else {
      const finalObj: finalWriteData = {
        vid: paramObj.Vid,
        value:
          treeData?.parameters[index].Decimals === 1
            ? parseFloat(value) * 10
            : parseFloat(value),
        decimal: treeData?.parameters[index].Decimals,
      };
      const vidData: number = finalObj.vid;
      const vidValue: number = writeComplements(finalObj?.value);
      const vidDecimal: number = finalObj?.decimal;
      const finalData = await dataWrite(
        vidData,
        vidValue,
        vidDecimal,
        connection,
        selectedAddress
      );
      console.log("write text value", finalData);
      if (finalData) {
        setWriteCompleted(true);
      }
    }
  };
  useEffect(() => {
    handleWriteCompleted();
  }, [
    writeCompleted,
    selectedparam,
    writeData,
    writeStatus,
    loadingIndex,
    showIcon,
  ]);

  const handleWriteCompleted = async () => {
    if (writeCompleted) {
      const displayData = selectedparam.find((obj: Paramdatas) => {
        return obj.key === writeData?.key;
      });

      if (
        displayData.paramValue &&
        displayData.paramValue.hasOwnProperty("value") &&
        displayData.paramValue.value === writeData?.paramValue
      ) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setWriteStatus("success");
      } else if (
        displayData.paramValue &&
        displayData.paramValue === writeData?.paramValue
      ) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setWriteStatus("success");
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setWriteStatus(null);
      }
    }
  };

  const getParamValue = (paramObj: ParameterType, index: number) => {
    const allvalues: FinalDataValue[] | any = getMatchedValue(
      paramObj?.EnumIdx,
      paramObj?.Min,
      treeData
    );
    if (writeCompleted) {
      const displayData = selectedparam.find((obj: Paramdatas) => {
        return obj.key === writeData?.key;
      });
      if (displayData.paramValue.hasOwnProperty("value")) {
        displayData.paramValue = writeData?.paramValue;
        setSelectedParam([...selectedparam]);
        setWriteCompleted(false);
        setShowIcon(true);
        setTimeout(() => {
          setShowIcon(false);
        }, 1000);
      } else {
        displayData.paramValue = writeData?.paramValue;
        setSelectedParam([...selectedparam]);
        setWriteCompleted(false);
        setShowIcon(true);
        setTimeout(() => {
          setShowIcon(false);
        }, 1000);
      }
    }
    const currentValue = selectedparam?.find((paramData: Paramdatas) => {
      return paramData.key === index;
    })?.paramValue;

    if (paramObj?.EnumIdx >= 0 && allvalues?.length) {
      const options: SelectInputOption[] = getSelectionOptions(allvalues);
      const defaultValue: number = updatedValues(paramObj, treeData);

      options.map((item: SelectInputOption) => {
        return item.value;
      });

      const complement: number = readComplements(defaultValue);
      const allOptions: SelectInputOption = options[complement];

      const selectedOptions: SelectInputOption | undefined = options.find(
        (option: SelectInputOption) => {
          const value: number = Number(option?.value);
          return value === complement;
        }
      );
      finalData(options, selectedOptions, allOptions);

      const iconDisplay = (
        options: SelectInputOption[],
        selectedOptions: SelectInputOption | undefined,
        paramObj: ParameterType
      ) => {
        const defaultData: number = getDefaultData(paramObj);

        const displayedicon: SelectInputOption = finalData(
          options,
          selectedOptions,
          allOptions
        );
        const finalDefaultValue: number = Number(displayedicon?.value);
        if (defaultData !== finalDefaultValue) {
          return true;
        } else {
          return false;
        }
      };
      return (
        <>
          <SelectInput
            name="selectInput"
            options={options}
            styles={{
              root: {
                width: "200px",
              },
            }}
            value={currentValue}
            onChange={(e: selectInputData) => {
              handleSelectedParam(e, paramObj, index);
            }}
            endInputAddon={
              loadingIndex === index && writeStatus !== "success" ? (
                <Spinner size={SpinnerSize.small} />
              ) : (
                loadingIndex === index &&
                showIcon && (
                  <Icon
                    glyph={icons.CHECK_CIRCLE}
                    size={32}
                    styles={{
                      root: {
                        color: "#14c510",
                      },
                    }}
                  />
                )
              )
            }
          />
          {iconDisplay(options, selectedOptions, paramObj) && (
            <Icon glyph={icons.NOTE_IC} />
          )}
        </>
      );
    }

    return (
      <>
        <TextInput
          name="textInput"
          accept={index.toString()}
          min={treeData?.parameters[index].Min}
          max={treeData?.parameters[index].Max}
          value={currentValue}
          onChange={(event) => {
            handleOnParamChange(event.target.value, index);
          }}
          onBlur={(e) => {
            handleBlur(e, index, paramObj);
          }}
          endInputAddon={
            <InputAddon position="end">
              {loadingIndex === index && !writeStatus ? (
                <Spinner size={SpinnerSize.small} />
              ) : (
                loadingIndex === index &&
                showIcon && (
                  <Icon
                    glyph={icons.CHECK_CIRCLE}
                    size={32}
                    styles={{
                      root: {
                        color: "#14c510",
                      },
                    }}
                  />
                )
              )}
              <Div mt={10}>{getUnitValue(paramObj)}</Div>
            </InputAddon>
          }
          styles={{
            root: {
              width: "200px",
            },
          }}
        />

        {isDefaultData(updatedValues(paramObj, treeData), paramObj, index) && (
          <Icon glyph={icons.NOTE_IC} />
        )}
      </>
    );
  };
  const getUnitValue = (paramObj: ParameterType) => {
    const unitData: EngUnitTypes = treeData?.engunittypes[paramObj?.EngUnitIdx];
    return unitData?.Text;
  };
  const updateddefaultValues = (data: ParameterType, index: number) => {
    const decimals: number = treeData?.parameters[index].Decimals;
    const value: number = data.Default;
    if (decimals === 1) {
      return value.toFixed(1);
    } else {
      return value.toString();
    }
  };
  const handleRowSelect = (item: TreeItem) => {
    const groupsData: Group[] = treeData?.groups;
    const result = groupsData.find((groups) => groups?.Idx === item?.id);
    const dataItems = result?.Items;
    const selectedParameters: ParameterTableData[] = [];
    const ParametersDatas: ParametersData = {
      group: result?.Text,
      parameters: selectedParameters,
    };

    dataItems?.map((item: updatedItem) => {
      return selectedParameters?.push({
        name: treeData?.parameters[item?.Index].Name,
        text: treeData?.parameters[item?.Index].Text,
        max: treeData?.pnuValues
          ? formattedMaxMinValues(
              treeData?.parameters[item?.Index].Max,
              treeData?.parameters[item?.Index].Decimals
            )
          : (treeData?.parameters[item?.Index].Max).toString(),
        min: treeData?.pnuValues
          ? formattedMaxMinValues(
              treeData?.parameters[item?.Index].Min,
              treeData?.parameters[item?.Index].Decimals
            )
          : (treeData?.parameters[item?.Index].Min).toString(),
        label: treeData?.parameters[item?.Index].Label,
        defaultValue: treeData?.pnuValues
          ? updateddefaultValues(treeData?.parameters[item?.Index], item?.Index)
          : getDefaultData(treeData?.parameters[item?.Index]),
        values: getParamValue(treeData?.parameters[item?.Index], item?.Index),
        units: getUnitValue(treeData?.parameters[item?.Index]),
        id: item?.Index,
      });
    });
    setSelectedItem(item);
    settreeviewData(ParametersDatas);
  };

  return (
    <Div>
      {isLoading ? (
        <Spinner
          styles={{
            root: {
              m: "250px",
            },
          }}
        />
      ) : (
        <SplitView
          leftPane={
            <ParameterTreeView
              treeData={data}
              setSelectedParameter={handleRowSelect}
            />
          }
          rightPane={
            <>
              <Div
                style={{
                  height: "90px",
                  border: "20px",
                  borderWidth: "20px",
                }}
              >
                {" "}
                <Div
                  style={{
                    marginLeft: "750px",
                    marginTop: "50px",
                    fontSize: "15px",
                  }}
                >
                  <Div>
                    CONTROLLER NAME:{" "}
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {data?.info?.name}
                    </span>
                  </Div>

                  <Div>
                    VARIANT:{"        "}
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {data?.info?.variant}
                    </span>
                  </Div>

                  <Div>
                    SOFTWARE VERSION:{" "}
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {data?.info?.softwareVersion}
                    </span>
                  </Div>

                  <Div>
                    CODE NUMBER:{" "}
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {data?.info?.codeNumber}
                    </span>
                  </Div>
                </Div>
              </Div>
              <img
                src={`${IMAGE_BASE64}${onlineControllerImg}`}
                alt=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-110px",
                  marginLeft: "960px",
                  right: "100px",
                  width: "200px",
                  height: "120px",
                }}
              />

              <CommandBar
                items={[
                  {
                    key: "1",
                    text: "TABLE VIEW",
                    disabled: false,
                    styles: {
                      root: {
                        border: "3px solid black",
                        borderRight: "0px",
                        borderLeft: "0px",
                        borderTop: "0px",
                        fontWeight: "bold",
                        color: "black !important",
                      },
                    },
                  },
                ]}
              />

              <Div style={{ overflow: "auto", maxHeight: "700px" }}>
                {treeviewData ? <TreeTable ParamData={treeviewData} /> : null}
              </Div>
            </>
          }
        />
      )}
    </Div>
  );
};

export default View;
