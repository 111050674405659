import { fetchData } from "../action/fetch";
import { SAVE_FILE_URL } from "../constants";
import { saveFileValueType } from "../types/controllerData.types";

export const getSaveProjectData = async (
  projectName: string,
  projectData: saveFileValueType[],
  openProject: boolean,
  fileType: string,
  isElectron: boolean
) => {
  try {
    const saveProjecturl: string =
      process.env.APPSETTING_CDF_URL || SAVE_FILE_URL;
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      mode: "no-cors",
    };
    const saveProjectData = {
      projectData: projectData,
      openProject: openProject,
      fileExtension: fileType,
    };
    const resData = await fetchData(saveProjecturl, {
      newProjectName: projectName,
      newProjectData: saveProjectData,
      isElectron: isElectron,
      headers,
    });
    return resData?.data?.message;
  } catch (error) {
    console.error("Error new Project save API response:", error);
  }
};
