import { useState } from "react";
import JSZip from "jszip";
import { Input, Button, H3 } from "@danfoss/etui-core";
import { getCdfData } from "../Services/cdfApi";
import { Div } from "@danfoss/etui-system-elements";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import {
  CDFFILEFORMAT,
  Convertion,
  UPLOAD_TEXT,
  ZIPFILEFORMAT,
} from "../constants";

export interface CDFProps {
  cdfData: string;
}
export interface cdfData {
  content: string;
}
export interface cdfAPIData {
  error?: string;
  message?: string;
}
export const CDFPage = () => {
  const [file, setFile] = useState<Uint8Array>();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const cdfFile: File = event.target.files[0];
    if (cdfFile.name.endsWith(CDFFILEFORMAT)) {
      const zip: JSZip = new JSZip();
      zip.file(cdfFile.name.replace(CDFFILEFORMAT, ZIPFILEFORMAT), cdfFile);
      const zipBlob: Blob = await zip.generateAsync({ type: Convertion });
      const zipFileData: ArrayBuffer = await zipBlob.arrayBuffer();
      const uintZipData: Uint8Array = new Uint8Array(zipFileData);
      setFile(uintZipData);
    }
  };

  const readNestedFiles = async (outerZipFile: Uint8Array) => {
    try {
      const outerZip = new JSZip();
      const outerZipContents = await outerZip.loadAsync(outerZipFile);
      const fileContents = [];
      for (const fileName in outerZipContents.files) {
        const file: JSZip.JSZipObject = outerZipContents.files[fileName];
        if (file.name.endsWith(ZIPFILEFORMAT)) {
          const nestedZipFileContent: Uint8Array = await file.async(
            "uint8array"
          );
          const nestedZipContents: any = await readNestedFiles(
            nestedZipFileContent
          );
          fileContents.push({ nestedZipContents });
        } else {
          const content: string = await file.async("base64");
          fileContents.push({ content });
        }
      }
      return fileContents;
    } catch (error) {
      console.error("Error reading nested zip files:", error);
      return [];
    }
  };
  const handleFileUpload = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (file) {
      const fileContents = await readNestedFiles(file);
      const cdfContents: string = fileContents[0]?.nestedZipContents.map(
        (item: cdfData) => {
          return item.content;
        }
      );
      const cdfData: CDFProps = { cdfData: cdfContents };
      const value: cdfAPIData = await getCdfData(cdfData);
      if (value?.message) {
        Notification.success({
          message: "Success",
          description: value?.message,
          duration: 3,
          theme: defaultTheme,
        });
      } else {
        Notification.error({
          message: "Error",
          description: value?.error,
          duration: 3,
          theme: defaultTheme,
        });
      }
    } else {
      Notification.warn({
        message: "Warning",
        description: "Please upload a file !!",
        duration: 3,
        theme: defaultTheme,
      });
    }
  };
  return (
    <Div
      style={{
        marginTop: "100px",
      }}
    >
      <Div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form>
          <H3 style={{ marginTop: "30px", textAlign: "center" }}>
            CDF FILE UPLOAD
          </H3>

          <Input
            type="file"
            onChange={handleFileChange}
            multiple={false}
            accept=".cdf"
            style={{
              marginTop: "30px",
              border: "0px",
              alignContent: "center",
              justifyContent: "center",
            }}
          />
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="input_submit"
              onClick={handleFileUpload}
              data-testid="Uploadbtn"
              small={true}
              variant="primary"
              styles={{
                root: {
                  m: "15px",
                },
              }}
            >
              {UPLOAD_TEXT}
            </Button>
          </Div>
        </form>
      </Div>
    </Div>
  );
};
