import { fetchData } from "../action/fetch";
import { OPEN_PROJECT_URL } from "../constants";

export const getOpenProjectData = async (Name: string) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  };
  const openProjecturl: string = OPEN_PROJECT_URL;
  try {
    const projectContent = await fetchData(openProjecturl, {
      projectName: Name,
      headers,
    });
    return projectContent?.data;
  } catch (err) {
    console.error("Error in Api get ", err);
  }
};
