import { Div, P } from "@danfoss/etui-system-elements";
import React, { useEffect, useState } from "react";
import {
  Form,
  Checkbox,
  TextInput,
  SelectInputOption,
  SelectInput,
  H6,
  Modal,
  Button,
  Icon,
  icons,
} from "@danfoss/etui-core";
import { useLayout } from "../components/context/layoutConfig";
import {
  CodeNumberDBResponse,
  eventData,
  cdfDataValue,
} from "../types/controllerData.types";
import {
  SELECT_CODE_NUMBER_TEXT,
  CODE_NUMBER_TEXT,
  SELECT_VERSION_TEXT,
  VERSION_TEXT,
  GIVE_FILENAME_TEXT,
  MAX_CHAR_TEXT,
  GIVE_DESCRIPTION_TEXT,
  USE_WIZARD_TEXT,
  MAX_CHARS_TEXT,
  AKCC_TEXT,
  SPECIAL_CHAR_TEXT,
  OK_TEXT,
  FILENAME_WARN_TEXT,
  UNSUPPORTED_CHAR_TEXT,
} from "../constants";
import { fileNameValid } from "../utils/InputValidation";

export const ConfigurationPage = (configurationData: cdfDataValue[]) => {
  const {
    selectedDeviceName,
    selectedCodeNumber,
    setSelectedCodeNumber,
    setSelectedSWVersion,
    setNewProjectName,
    setDisableFinish,
  } = useLayout();
  const [updatedSWVersion, setUpdatedSWVersion] = useState<SelectInputOption[]>(
    []
  );
  const [currentVersion, setCurrentVersion] = useState<SelectInputOption>();
  const [openInfoPopup, setOpenInfoPopup] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDeviceName.length !== 0) {
      setSelectedCodeNumber(uniqueCodeNumber[0]);
      setSelectedSWVersion(uniqueSoftwareVersion[0]);
    }
  }, [selectedDeviceName]);

  useEffect(() => {
    const updatedVersions: SelectInputOption[] = [];
    configurationData.forEach((item: cdfDataValue) => {
      item?.cdfData?.device?.Header?.CodeNumbers?.forEach(
        (item_Text: CodeNumberDBResponse) => {
          if (selectedCodeNumber?.value === item_Text?.CodeNumber) {
            const softwareVersion = {
              value: `${item?.cdfData?.device?.Header?.SWVersion / 100}`,
              label: `${item?.cdfData?.device?.Header?.SWVersion / 100}`,
            };
            updatedVersions.push(softwareVersion);
          }
        }
      );
    });
    setUpdatedSWVersion(updatedVersions);
  }, [selectedCodeNumber]);

  const softwareVersionFinal: SelectInputOption[] = [];
  const codeNumber: SelectInputOption[] = [];
  let uniqueSoftwareVersion: SelectInputOption[] = [];
  let uniqueCodeNumber: SelectInputOption[] = [];

  configurationData?.map((item: cdfDataValue) => {
    item?.cdfData?.device?.Header?.CodeNumbers?.map(
      (item_Text: CodeNumberDBResponse) => {
        let softwareVersion: SelectInputOption;
        if (selectedDeviceName.slice(0, 13) === item_Text?.Text.slice(0, 13)) {
          softwareVersion = {
            value: `${item?.cdfData?.device?.Header?.SWVersion / 100}`,
            label: `${item?.cdfData?.device?.Header?.SWVersion / 100}`,
          };
          return softwareVersionFinal.push(softwareVersion);
        }
        return null;
      }
    );

    uniqueSoftwareVersion = softwareVersionFinal.filter(
      (obj, index, softwareVersionFinal) =>
        index ===
        softwareVersionFinal?.findIndex(
          (item) => item?.label === obj?.label && item?.value === obj?.value
        )
    );

    item?.cdfData?.device?.Header?.CodeNumbers?.map(
      (item_code: CodeNumberDBResponse) => {
        let Code_Number: SelectInputOption;
        if (
          selectedDeviceName?.slice(0, 13) === item_code?.Text?.slice(0, 13)
        ) {
          if (item_code.Text.slice(0, 5) === AKCC_TEXT) {
            Code_Number = {
              value: item_code?.CodeNumber,
              label: `${item_code?.CodeNumber}-${item_code?.Text}`,
            };
            return codeNumber?.push(Code_Number);
          } else {
            Code_Number = {
              value: item_code?.CodeNumber,
              label: item_code?.CodeNumber,
            };
            return codeNumber?.push(Code_Number);
          }
        }
        return null;
      }
    );
  });

  uniqueCodeNumber = codeNumber?.filter(
    (obj, index, codeNumber) =>
      index ===
      codeNumber.findIndex(
        (item) => item?.label === obj?.label && item?.value === obj?.value
      )
  );

  const handelInputCode = (e: SelectInputOption) => {
    setSelectedCodeNumber(e);
  };
  const handelInputVersion = (event: SelectInputOption) => {
    setCurrentVersion(event);
    setSelectedSWVersion(event);
  };
  const handleInputChange = (e: eventData) => {
    const isValid = fileNameValid(e.target.value);
    if (isValid === false) {
      setOpenInfoPopup(true);
      setDisableFinish(true);
    } else {
      setNewProjectName(e.target.value);
      setDisableFinish(false);
    }
  };
  const handleInfoClose = () => {
    setOpenInfoPopup(false);
  };
  return (
    <Div>
      <Form styles={{ root: { pl: "10px", pr: "10px", pt: "10px" } }}>
        <H6>{SELECT_CODE_NUMBER_TEXT}</H6>
        <SelectInput
          name={CODE_NUMBER_TEXT}
          options={uniqueCodeNumber}
          defaultValue={uniqueCodeNumber[0]}
          onChange={(e) => {
            handelInputCode(e);
          }}
          size={"small"}
          label={CODE_NUMBER_TEXT}
          styles={{ root: { pb: "10px" } }}
        />
        <H6>{SELECT_VERSION_TEXT}</H6>
        <SelectInput
          name={VERSION_TEXT}
          label={VERSION_TEXT}
          options={updatedSWVersion}
          value={currentVersion || updatedSWVersion[0]}
          onChange={(e) => {
            handelInputVersion(e);
          }}
          size={"small"}
          styles={{ root: { pb: "10px" } }}
        ></SelectInput>
        <H6>{GIVE_FILENAME_TEXT} </H6>
        <TextInput
          required={true}
          className="name"
          id="name"
          maxLength={20}
          size="small"
          label={MAX_CHAR_TEXT}
          styles={{ root: { pb: "10px" } }}
          onChange={handleInputChange}
        />
        <H6> {GIVE_DESCRIPTION_TEXT} </H6>
        <Textarea></Textarea>
        <Checkbox />
        {USE_WIZARD_TEXT}
      </Form>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openInfoPopup}
        onClose={handleInfoClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={UNSUPPORTED_CHAR_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleInfoClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {OK_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "130px",
            },
          }}
        ></Icon>
        <P textAlign={"center"} style={{ marginLeft: "30px" }}>
          {SPECIAL_CHAR_TEXT}
        </P>
        <P textAlign={"center"} style={{ marginLeft: "25px" }}>
          {FILENAME_WARN_TEXT}
        </P>
      </Modal>
    </Div>
  );
};
export const Textarea: React.FC = () => {
  const { newProjectDescription, setNewProjectDescription } = useLayout();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleInputDescription = (e: eventData) => {
    setNewProjectDescription(e.target.value);
  };
  return (
    <Div style={{ position: "relative" }}>
      <label
        style={{
          position: "absolute",
          top: isFocused || newProjectDescription ? "5px" : "10px",
          left: "7px",
          fontSize: isFocused || newProjectDescription ? "12.5px" : "14px",
          color: "#70767E",
          fontFamily: "sans-serif",
          transition: "top 0.2s ease, font-size 0.2s ease",
          fontWeight: 100,
        }}
      >
        {MAX_CHARS_TEXT}
      </label>
      <textarea
        value={newProjectDescription}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleInputDescription}
        style={{
          fontSize: "14px",
          border: "1px solid #B4BCC3",
          boxShadow: isFocused ? "0px 0px 5px  #B4BCC3 " : "0 0 0",
          paddingRight: "10px",
          width: "100%",
          height: "100px",
          transition: "font-size 0.2s ease",
          fontFamily: "sans-serif",
          fontWeight: 100,
          paddingTop: "20px",
          paddingLeft: "7px",
          resize: "none",
        }}
        rows={4}
        wrap={"hard"}
        maxLength={250}
      ></textarea>
    </Div>
  );
};
