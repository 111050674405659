import {
  icons,
  TreeViewItemContentDiv,
  TreeViewItemToggleDiv,
  Icon,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';

export function MainTreeItem({ options, item }:any) {
  const toggleDivId = 'toggle';

  const {
    level,
    hasNestedItems,
    isOpen,
    isRowSelected,
    getToggleProps,
    getItemRowProps,
  } = options;
  const { onClick, onKeyDown } = getItemRowProps();
  const { onClick: onToggle, ...toggleProps } = getToggleProps();
  function handleClick(e:any) {
    const isToggle =
      (e.target as HTMLDivElement).id === toggleDivId ||
      (e.target as HTMLDivElement).id === icons.CHEVRON_DOWN;
    if (isToggle) {
      e.preventDefault();
      return;
    }
    onClick(e);
    if (!isOpen) { 
      onToggle();
    }
  }

  const isItemSelected = isRowSelected(item);


  return (
    <TreeViewItemContentDiv
      isRowSelected={isItemSelected}
      onClick={handleClick}
      onKeyDown={onKeyDown} 
      {...item}
      width="100%"
      fontWeight={isItemSelected ? 'bold' : 'initial'}
    >
      <TreeViewItemToggleDiv
        lineHeight={0}
        hasNestedItems={hasNestedItems}
        isRowSelected={isItemSelected}
        level={level}
        onClick={onToggle}
        testId={`${item.id}-tree-item`}
        {...toggleProps}
      >
        <Icon glyph={icons.CHEVRON_DOWN} rotate={isOpen ? 0 : -90} size={16} />
      </TreeViewItemToggleDiv>
      <Div testId="parent-div" display="flex" width="100%" height="100%">
        <Icon
          glyph={item?.glyph}
          size={32}
        />
        <Div mt="5px">{item?.name}</Div>
         
      </Div>
    </TreeViewItemContentDiv>
  );
}
