import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { defaultTheme } from "@danfoss/etui-themes";
import { ThemeProvider } from "@danfoss/etui-system";
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <ThemeProvider theme={defaultTheme}>
      <App />
    </ThemeProvider>
  </HashRouter>
);
reportWebVitals();
