import {
  FinalDataValue,
  FinalMatchedValue,
  MainData,
  Values,
  VidDatas,
  updatedTreeData,
} from "../types/controllerData.types";
import { readComplements } from "./readComplement";
import { ParameterType } from "../types/controllerData.types";
import { SelectInputOption } from "@danfoss/etui-core";

export const getDefaultData = (paramObj: ParameterType) => {
  return paramObj.Default;
};
export const decimalValues = (
  data: number,
  index: number,
  treeData: MainData
) => {
  const decimals: number = treeData?.parameters[index].Decimals;
  const decimalData: number = data * 10;
  const updatedDecimalData: number = readComplements(decimalData);

  if (decimals === 1) {
    const finalUpdatedDecimalData: string =
      updatedDecimalData < 0
        ? (updatedDecimalData / 10).toString()
        : (updatedDecimalData / 10).toFixed(1);
    return finalUpdatedDecimalData;
  } else {
    const finalUpdatedDecimalData: string = (
      updatedDecimalData / 10
    ).toString();
    return finalUpdatedDecimalData;
  }
};
export const formattedMaxMinValues = (data: number, decimals: number) => {
  if (decimals === 1) {
    return data.toFixed(1);
  } else {
    return data.toString();
  }
};
export const updatedValues = (paramObj: ParameterType, treeData: MainData) => {
  if (treeData?.pnuValues) {
    const matchingItem: VidDatas | any = treeData.pnuValues.find(
      (item: { pnu: number }) => item.pnu === paramObj.Vid
    );
    return matchingItem ? matchingItem.value : paramObj.Default;
  } else {
    return paramObj.Default;
  }
};
export const getSelectionOptions = (values: FinalMatchedValue[]) => {
  const options: SelectInputOption[] = values.map(
    (data: FinalMatchedValue) => ({
      value: data.value,
      label: data.text,
    })
  );

  return options;
};

export const getMatchedValue = (
  enumIdx: number,
  mindata: number,
  treeData: MainData
) => {
  return treeData?.enumerations[enumIdx]?.Values?.map((item: Values) => {
    const finalObj: FinalDataValue = {
      text: item?.Text,
      value: item?.Value,
      min: mindata,
    };
    return finalObj;
  });
};
export const finalData = (
  options: SelectInputOption[],
  data: SelectInputOption | undefined,
  allOptions: SelectInputOption
) => {
  const comboValues: SelectInputOption | undefined = options?.find(
    (option: SelectInputOption) => option?.value === data?.value
  );
  return comboValues ? comboValues : allOptions;
};
