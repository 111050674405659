import {
  Button,
  Icon,
  InputAddon,
  Modal,
  SelectInput,
  SelectInputOption,
  icons,
} from "@danfoss/etui-core";
import { useState } from "react";
import { Div } from "@danfoss/etui-system-elements";
import { useLayout } from "../components/context/layoutConfig";
import { DONE_TEXT } from "../constants";

export const PreferencePage = () => {
  const {
    setSelectedAddress,
    setOpenSettings,
    openSettings,
    disableDone,
    setDisableDone,
    controllerInfoData,
    setOnlineControllerImg
  } = useLayout();
  const [name, setName] = useState<SelectInputOption>();

  const controllerOptions: SelectInputOption[] = [
    { value: "0x00", label: "EKC22x" },
    { value: "0x01", label: "EKE-100" },
  ];

  const handleClose = () => {
    setOpenSettings(false);
  };
  const handleButtonDone = () => {
    setOpenSettings(false);
    if (controllerInfoData && name) {
      const filteredData = controllerInfoData.filter(imageData => 
        imageData.name.slice(0, 3) === name?.label.slice(0, 3)
      );
    
      if (filteredData) {
        setOnlineControllerImg(filteredData[0].productImage);
      }
    }
    
  };
  return (
    <Div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "250px", width: "350px" } }}
        isOpen={openSettings}
        onClose={handleClose}
        header={<Modal.Header title="Controller Settings" />}
        actions={
          <Div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="primary"
              onClick={handleButtonDone}
              disabled={disableDone}
              styles={{ root: { height: "30px", width: "80px" } }}
            >
              {DONE_TEXT}
            </Button>
          </Div>
        }
      >
        <SelectInput
          name="selectInput"
          label="Select Controller"
          options={controllerOptions}
          value={name}
          onChange={(e:SelectInputOption) => {
            setName(e);
            setSelectedAddress(e);
            setDisableDone(false);
          }}
          startInputAddon={
            <InputAddon position="start" size="small">
              <Icon glyph={icons.CHECK_CIRCLE} />
            </InputAddon>
          }
          styles={{
            root: {
              width: "180px",
              ml: "70px",
              mt: "20px",
            },
          }}
        />
      </Modal>
    </Div>
  );
};
