import { Button, H3 } from "@danfoss/etui-core";
import { Div } from "@danfoss/etui-system-elements";
import { useEffect } from "react";
import { useLayout } from "../components/context/layoutConfig";

export const DefaultPage = ({
  handleconnection,
}: {
  handleconnection: () => Promise<void>;
}) => {
  const { setIsElectronApp, setIsOnline } = useLayout();

  useEffect(() => {
    if (window.process) {
      setIsElectronApp(true);
    } else {
      setIsElectronApp(false);
    }
  }, [setIsElectronApp]);

  useEffect(() => {
    if (navigator.onLine) {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  }, [setIsElectronApp]);

  return (
    <Div
      style={{
        marginTop: "100px",
      }}
    >
      <Div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <H3 style={{ marginTop: "30px", textAlign: "center" }}>
          Click here to connect the Controller
        </H3>
        <Button
          id="hiddenButton"
          onClick={handleconnection}
          small={true}
          variant="primary"
          styles={{
            root: {
              mt: "30px",
            },
          }}
        >
          Connect
        </Button>
      </Div>
    </Div>
  );
};
