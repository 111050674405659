import { fetchData } from "../action/fetch";
import { HOME_URL } from "../constants";
import { HostProps } from "../types/controllerData.types";

export const getControllerData = async (host: HostProps) => {
  const homeUrl: string = HOME_URL;
  try {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const controllerData = await fetchData(homeUrl, {
      hostValue: host,
      headers,
    });
    const responseData = controllerData?.data;
    return responseData;
  } catch (error) {
    console.error("Error parsing controller API response:", error);
  }
};
