import { styled } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';

export const Main = styled(Div)`
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  position: absolute;
  outline: none;
  overflow: hidden;
  user-select: text;
  flex-direction: row;
  left: 0px;
  right: 0px;
`;
