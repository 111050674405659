import {
  Button,
  CommandBar,
  H6,
  Icon,
  InputAddon,
  Modal,
  SelectInput,
  SelectInputOption,
  SelectedValueType,
  Spinner,
  TextInput,
  TreeItem,
  TreeView,
  icons,
} from "@danfoss/etui-core";
import { Div, P } from "@danfoss/etui-system-elements";
import { SplitView } from "../components/components/SplitView";
import { useLayout } from "../components/context/layoutConfig";
import { iconsData } from "../components/ParameterTreeView";
import { formattedMaxMinValues, getDefaultData } from "../utils/TreeData";
import {
  ParameterTableData,
  ParametersData,
  updatedItem,
  ParameterType,
  EngUnitTypes,
  Enumerations,
} from "../types";
import { useEffect, useRef, useState } from "react";
import { MainTreeItem } from "../components/components/components";
import TreeTable from "../components/ParameterTreeView/TreeTable";
import {
  ALL_TEXT,
  IMAGE_BASE64,
  ITEM_TYPE_TEXT,
  SUCCESS_TEXT,
  SAVE_MESSAGE,
  SAVE_TEXT,
  ENUM_IDX_VALUE,
  OK_TEXT,
  INVALID_INPUT_MESSAGE,
  INVALID_VALUE_TEXT,
  INFO_INTEGER_TEXT,
} from "../constants";
import {
  Group,
  Item,
  changedValueType,
  eventData,
  eventStyleData,
  saveFileValueType,
} from "../types/controllerData.types";
import { getSaveProjectData } from "../Services/saveFileApi";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import { SaveAsPopup } from "./SaveAsPopup";
import { DownloadPopup } from "./DownloadPopup";
import { Upload } from "./Upload";
import {
  parameterValueValidation,
  validateIntegers,
} from "../utils/InputValidation";

export const ProjectCreationPage = () => {
  const {
    selectedDeviceName,
    selectedSWVersion,
    selectedCodeNumber,
    controllerImg,
    newProjectName,
    selectedCdfFile,
    openProjectData,
    openProject,
    fileExtension,
    controllerFamily,
    isElectronApp,
  } = useLayout();

  const [openSections, setOpenSections] = useState<SelectedValueType[]>([]);
  const [treeTableData, setTreeTableData] = useState<ParametersData>();
  const [currentSelectedRow, setCurrentSelectedRow] = useState<TreeItem>();
  const [menuTreeData, setMenuTreeData] = useState<TreeItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<TreeItem>();
  const [saveFileValue, setSaveFileValue] = useState<saveFileValueType[]>([]);
  const [inputValue, setInputValue] = useState<string[]>([]);
  const [inputSelectOption, setInputSelectOption] = useState<
    SelectInputOption[]
  >([]);
  const [textInputIcon, setTextInputIcon] = useState<boolean[]>([]);
  const [selectInputIcon, setSelectInputIcon] = useState<boolean[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTextValue, setCurrentTextValue] = useState<string>("1");
  const [openInfoPopup, setOpenInfoPopup] = useState<boolean>(false);
  const [integerValue, setIntegerValue] = useState<boolean>(false);
  const [withinMinMax, setWithinMinMax] = useState(false);
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const textRef = useRef(null);
  const [changedValue, setChangedValue] = useState<changedValueType[]>([]);
  const [inputIndex, setInputIndex] = useState<number>();

  useEffect(() => {
    processMenuTree();
  }, [selectedCdfFile?.cdfData?.device?.Groups]);

  useEffect(() => {
    if (selectedItem) {
      selectedRow(selectedItem);
      setCurrentSelectedRow(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (menuTreeData.length) {
      let sectionsIds = [];
      sectionsIds.push(menuTreeData.at(0)?.id);
      sectionsIds.push(menuTreeData.at(0)?.items?.at(0)?.id);
      sectionsIds.push(menuTreeData.at(0)?.items?.at(0)?.items?.at(0)?.id);
      const menuExpand: TreeItem | any = menuTreeData
        .at(0)
        ?.items?.at(0)
        ?.items?.at(0);
      setOpenSections(sectionsIds);
      setCurrentSelectedRow(menuExpand);
    }
  }, [menuTreeData.length]);

  useEffect(() => {
    if (currentSelectedRow) {
      selectedRow(currentSelectedRow);
    }
  }, [
    inputValue,
    inputSelectOption,
    textInputIcon,
    selectInputIcon,
    openInfoPopup,
    changedValue,
  ]);

  useEffect(() => {
    if (openProject === true) {
      openProjectData?.cdfData?.device?.Parameters.forEach((param, index) => {
        iconsDisplayOpenProject(index);
      });
    }
  }, []);

  useEffect(() => {
    if (openInfoPopup === true) {
      selectedCdfFile?.cdfData?.device?.Parameters.forEach((param, index) => {
        resetOnInvalidInput(index, param.Vid, param.Name);
        if (currentSelectedRow) {
          selectedRow(currentSelectedRow);
        }
      });
    }
  }, [openInfoPopup]);

  const resetOnInvalidInput = (index: number, Vid: number, Name: string) => {
    const parameterValues: ParameterType[] = openProject
      ? openProjectData?.cdfData?.device?.Parameters
      : selectedCdfFile?.cdfData?.device?.Parameters;

    if (inputIndex === index) {
      const resetValues = updateddefaultValues(parameterValues[index], index);
      if (resetValues) {
        setInputValue((prevInputValues) => {
          const updatedValues = [...prevInputValues];
          updatedValues[index] = resetValues;
          return updatedValues;
        });
        const saveResetValue: saveFileValueType = {
          value: resetValues,
          vid: Vid,
          name: Name,
        };
        setSaveFileValue((prevState) => [...prevState, saveResetValue]);
      }
    }
  };

  const iconsDisplayOpenProject = (index: number) => {
    const openProjectValue = updateddefaultValues(
      openProjectData?.cdfData?.device?.Parameters[index],
      index
    );
    const defaultValueCdf = updateddefaultValues(
      selectedCdfFile?.cdfData?.device?.Parameters[index],
      index
    );

    if (openProjectValue === defaultValueCdf) {
      setSelectInputIcon((prevInput) => {
        const updatedChanged = [...prevInput];
        updatedChanged[index] = false;
        return updatedChanged;
      });
      setSelectInputIcon((prevInput) => {
        const updatedChanged = [...prevInput];
        updatedChanged[index] = false;
        return updatedChanged;
      });
    } else {
      setTextInputIcon((prevInput) => {
        const updatedChanged = [...prevInput];
        updatedChanged[index] = true;
        return updatedChanged;
      });
      setSelectInputIcon((prevInput) => {
        const updatedChanged = [...prevInput];
        updatedChanged[index] = true;
        return updatedChanged;
      });
    }
  };

  const allGroup = selectedCdfFile?.cdfData?.device?.Groups;

  const firstItem: string = allGroup?.[0]?.Text;

  const processItems = (items: Item[]): TreeItem[] => {
    if (!items) {
      return [];
    }

    const treeData: TreeItem[] = [];

    items.forEach((item: Item) => {
      if (item.ItemType === ITEM_TYPE_TEXT) {
        const nestedItems = processItems(
          selectedCdfFile?.cdfData?.device?.Groups[item.Index]?.Items
        );

        const processedItem: TreeItem = {
          id: selectedCdfFile?.cdfData?.device?.Groups[item.Index]?.Idx,
          name: selectedCdfFile?.cdfData?.device?.Groups[item.Index]?.Text,
          glyph: iconsData[item.Index]?.icon,
          items: nestedItems,
        };

        treeData.push(processedItem);
      }
    });

    return treeData;
  };

  const firstGroupItems: Item[] = allGroup?.[0]?.Items;

  const secondAllItems = processItems(firstGroupItems);

  const processMenuTree = () => {
    const treeViewData: TreeItem[] = [
      {
        id: 41,
        name: ALL_TEXT,
        items: [
          {
            id: 42,
            name: firstItem,
            items: secondAllItems,
          },
        ],
      },
    ];
    setMenuTreeData(treeViewData);
    setSelectedItem(secondAllItems[0]);
  };

  const handleOpenSectionChange = (
    sectionsOpenUpdated: SelectedValueType[]
  ) => {
    setOpenSections(sectionsOpenUpdated);
  };

  const getUnitValue = (paramObj: ParameterType) => {
    const unitData: EngUnitTypes =
      selectedCdfFile?.cdfData?.device?.EngUnitTypes[paramObj?.EngUnitIdx];
    return unitData?.Text;
  };

  const updateddefaultValues = (data: ParameterType, index: number) => {
    const decimals: number =
      selectedCdfFile?.cdfData?.device?.Parameters[index].Decimals;
    if (data?.EnumIdx === -1) {
      const value: number = data.Default;
      if (decimals === 1) {
        return value.toFixed(1);
      } else if (decimals === 2) {
        return value.toFixed(2);
      } else {
        return value.toString();
      }
    } else {
      const enumeration = selectedCdfFile?.cdfData?.device?.Enumerations.find(
        (Enum) => data.EnumIdx === Enum.Idx
      );
      if (enumeration) {
        const enumValue = enumeration.Values.find(
          (enumvalue) => enumvalue.Value === data.Default
        );
        if (enumValue) {
          return enumValue.Text;
        }
      }
    }
  };

  const handleMouseEnter = (e: eventStyleData) => {
    if (textRef.current) {
      e.textRef.currentTarget.style.fontWeight = "700";
    }
  };

  const handleMouseLeave = (e: eventStyleData) => {
    if (textRef.current) {
      e.textRef.currentTarget.style.fontWeight = "100";
    }
  };

  const getParamValue = (
    paramObj: ParameterType,
    index: number,
    enumerations: Enumerations[]
  ) => {
    const updateCurrentValue = (textValue: string) => {
      if (paramObj?.Idx === index) {
        setInputValue((prevInputValues) => {
          const updatedValues = [...prevInputValues];
          updatedValues[index] = textValue;
          return updatedValues;
        });
      }
    };

    const defaultTextInput = openProject
      ? updateddefaultValues(
          openProjectData?.cdfData?.device?.Parameters[index],
          index
        )
      : updateddefaultValues(
          selectedCdfFile?.cdfData?.device?.Parameters[index],
          index
        );
    const openDefaultInput = updateddefaultValues(
      selectedCdfFile?.cdfData?.device?.Parameters[index],
      index
    );
    const min: number = openProject
      ? openProjectData?.cdfData?.device?.Parameters[index]?.Min
      : selectedCdfFile?.cdfData?.device?.Parameters[index].Min;
    const max: number = openProject
      ? openProjectData?.cdfData?.device?.Parameters[index]?.Max
      : selectedCdfFile?.cdfData?.device?.Parameters[index].Max;
    const getInputBackgroundColor = (index: number) => {
      if (changedValue.length === 0) {
        return "white";
      }
      const isChanged = changedValue?.find((change) => change.index === index);
      return isChanged ? "#E8F0F2" : "white";
    };
    if (paramObj.EnumIdx === -1) {
      const handleTextInput = (e: eventData) => {
        const value: string = e?.target.value;
        const vid: number = paramObj?.Vid;
        const name: string = paramObj?.Name;
        setCurrentTextValue(value);
        const updatedValue = { value, vid, name };
        setSaveFileValue((prevState) => [...prevState, updatedValue]);
        updateCurrentValue(value);
        const changedData = { value, vid, name, index };
        setChangedValue((prevValues) => [...prevValues, changedData]);
        if (value !== openDefaultInput) {
          setTextInputIcon((prevInput) => {
            const updatedChanged = [...prevInput];
            updatedChanged[index] = true;
            return updatedChanged;
          });
        } else {
          setTextInputIcon((prevInput) => {
            const updatedChanged = [...prevInput];
            updatedChanged[index] = false;
            return updatedChanged;
          });
        }
      };
      const decimmalValue = openProject
        ? openProjectData?.cdfData?.device?.Parameters[index]?.Decimals
        : selectedCdfFile?.cdfData?.device?.Parameters[index].Decimals;
      const handleMouseLeave = () => {
        const result = parameterValueValidation(currentTextValue, min, max);
        const isIntegerValue = validateIntegers(
          currentTextValue,
          decimmalValue
        );

        setOpenInfoPopup(result || isIntegerValue);
        setWithinMinMax(result);
        setIntegerValue(isIntegerValue);
        setInputIndex(index);
      };
      return (
        <>
          <TextInput
            name="textInput"
            accept={index.toString()}
            min={selectedCdfFile?.cdfData?.device?.Parameters[index].Min}
            max={selectedCdfFile?.cdfData?.device?.Parameters[index].Max}
            defaultValue={defaultTextInput}
            value={inputValue[index]}
            onChange={(e) => {
              handleTextInput(e);
            }}
            endInputAddon={
              <InputAddon position="end">
                {" "}
                <Div mt={10}>{getUnitValue(paramObj)}</Div>
              </InputAddon>
            }
            onBlur={handleMouseLeave}
            styles={{
              root: {
                width: "200px",
                border: "0.5px #B4BCC3",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                height: "50px",
              },
              input: {
                height: "45px",
                fontWeight: 500,
              },
            }}
            style={{ background: getInputBackgroundColor(index) }}
          />
          {textInputIcon[index] && <Icon glyph={icons.NOTE_IC} />}
        </>
      );
    } else {
      let selectInputOptions: SelectInputOption[] = [];
      let defaultOption: SelectInputOption[] = [];
      enumerations.map((Enum) => {
        if (paramObj.EnumIdx === Enum.Idx) {
          Enum.Values.map((enumvalue) => {
            const options: SelectInputOption = {
              label: `${enumvalue.Text}`,
              value: `${enumvalue.Value}`,
            };
            if (enumvalue.Value === paramObj.Default) {
              return (
                defaultOption.push({
                  label: `${enumvalue.Text}`,
                  value: `${enumvalue.Value}`,
                }) && selectInputOptions.push(options)
              );
            }
            return selectInputOptions.push(options);
          });
        }
      });
      const updateSelectValue = (selectValue: SelectInputOption) => {
        if (paramObj?.Idx === index) {
          setInputSelectOption((prevInputValues) => {
            const updatedValues = [...prevInputValues];
            updatedValues[index] = selectValue;
            return updatedValues;
          });
        }
      };
      const defaultValue = updateddefaultValues(
        selectedCdfFile?.cdfData?.device?.Parameters[index],
        index
      );
      const handleSelectInput = (e: SelectedValueType) => {
        const selectValue: SelectedValueType = e;
        const value: string = selectValue?.value;
        const vid: number = paramObj?.Vid;
        const name: string = paramObj?.Name;
        const updatedValue = { value, vid, name };
        setSaveFileValue((prevState) => [...prevState, updatedValue]);
        const changedData = { value, vid, name, index };
        setChangedValue((prevValues) => [...prevValues, changedData]);
        updateSelectValue(selectValue);
        if (selectValue?.label !== defaultValue) {
          setSelectInputIcon((prevInput) => {
            const updatedChanged = [...prevInput];
            updatedChanged[index] = true;
            return updatedChanged;
          });
        } else {
          setSelectInputIcon((prevInput) => {
            const updatedChanged = [...prevInput];
            updatedChanged[index] = false;
            return updatedChanged;
          });
        }
      };

      return (
        <Div style={{ whiteSpace: "nowrap" }}>
          <SelectInput
            name="selectInput"
            options={selectInputOptions}
            defaultValue={inputSelectOption[index] || defaultOption}
            onChange={(e) => {
              handleSelectInput(e);
            }}
            styles={{
              root: {
                width: "200px",
                border: "0.5px #B4BCC3",
                fontSize: "14px",
                display: "inline-block",
                alignItems: "center",
                mt: "1px",
              },
              input: {
                height: "45px",
                bg: getInputBackgroundColor(index),
              },
            }}
            onMenuOpen={() => setIsToggle(true)}
            onMenuClose={() => setIsToggle(false)}
            formatOptionLabel={
              isToggle
                ? null
                : (item: SelectInputOption) => (
                    <div
                      style={{ color: "black", fontWeight: 100 }}
                      ref={textRef}
                      onMouseEnter={(e) => handleMouseEnter}
                      onMouseLeave={(e) => handleMouseLeave}
                    >
                      {item.label}
                    </div>
                  )
            }
          />
          {selectInputIcon[index] && (
            <Icon
              glyph={icons.NOTE_IC}
              styles={{ root: { display: "inline-block", ml: "0px" } }}
            />
          )}
        </Div>
      );
    }
  };

  const selectedRow = (item: TreeItem) => {
    const groupsData: Group[] = selectedCdfFile?.cdfData?.device?.Groups;
    const result = groupsData.find(
      (Groups: { Idx: number }) => Groups?.Idx === item?.id
    );
    const dataItems = result?.Items;
    const selectedParameters: ParameterTableData[] = [];
    const ParametersDatas: ParametersData = {
      group: result?.Text,
      parameters: selectedParameters,
    };
    setTreeTableData(ParametersDatas);
    setCurrentSelectedRow(item);
    dataItems?.map((item: updatedItem) => {
      return selectedParameters?.push({
        name: selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].Name,
        text: selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].Text,
        max:
          selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].EnumIdx ===
          ENUM_IDX_VALUE
            ? formattedMaxMinValues(
                selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].Max,
                selectedCdfFile?.cdfData?.device?.Parameters[item?.Index]
                  .Decimals
              )
            : "_",
        min:
          selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].EnumIdx ===
          ENUM_IDX_VALUE
            ? formattedMaxMinValues(
                selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].Min,
                selectedCdfFile?.cdfData?.device?.Parameters[item?.Index]
                  .Decimals
              )
            : "_",
        label: selectedCdfFile?.cdfData?.device?.Parameters[item?.Index].Label,
        defaultValue: selectedCdfFile?.cdfData?.device?.Parameters[item?.Index]
          .Max
          ? updateddefaultValues(
              selectedCdfFile?.cdfData?.device?.Parameters[item?.Index],
              item?.Index
            )
          : getDefaultData(
              selectedCdfFile?.cdfData?.device?.Parameters[item?.Index]
            ),
        values: openProject
          ? getParamValue(
              openProjectData?.cdfData?.device?.Parameters[item?.Index],
              item?.Index,
              openProjectData?.cdfData?.device?.Enumerations
            )
          : getParamValue(
              selectedCdfFile?.cdfData?.device?.Parameters[item?.Index],
              item?.Index,
              selectedCdfFile?.cdfData?.device?.Enumerations
            ),
        units: getUnitValue(
          selectedCdfFile?.cdfData?.device?.Parameters[item?.Index]
        ),
        id: item?.Index,
      });
    });
  };
  const handleClickSave = async () => {
    try {
      setLoading(true);
      const saveRes: string = await getSaveProjectData(
        newProjectName,
        saveFileValue,
        openProject,
        fileExtension,
        isElectronApp
      );
      if (saveRes === SAVE_MESSAGE) {
        setChangedValue([]);
        setLoading(false);
        Notification.success({
          message: SUCCESS_TEXT,
          description: SAVE_MESSAGE,
          duration: 3,
          theme: defaultTheme,
        });
      }
    } catch {
      console.error("Error saving File");
    }
  };
  const handleInfoClose = () => {
    setOpenInfoPopup(false);
    setWithinMinMax(false);
    setIntegerValue(false);
  };
  return (
    <Div
      style={{
        marginTop: "0px",
      }}
    >
      <SplitView
        leftPane={
          <>
            <Div style={{ overflow: "auto", maxHeight: "630px" }}>
              <TreeView
                data={menuTreeData}
                onRowSelect={selectedRow}
                onOpenSectionChange={handleOpenSectionChange}
                openSections={openSections}
                selectedRow={currentSelectedRow}
                styles={{
                  root: {
                    fontWeight: 700,
                  },
                  row: {
                    root: {
                      fontWeight: 700,
                    },
                  },
                }}
                rowTemplate={(props: any) => (
                  <MainTreeItem key={props.item.name} {...props} />
                )}
              ></TreeView>
            </Div>
          </>
        }
        rightPane={
          <>
            <Div
              style={{
                height: "100px",
                border: "20px",
                borderWidth: "20px",
              }}
            >
              {" "}
              <Div
                style={{
                  marginLeft: "700px",
                  marginTop: "30px",
                  fontSize: "15px",
                }}
              >
                <Div>
                  PROJECT NAME:{" "}
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {newProjectName}
                  </span>
                </Div>
                <Div>
                  CONTROLLER NAME:{" "}
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {controllerFamily}
                  </span>
                </Div>

                <Div>
                  VARIANT:{"        "}
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {selectedDeviceName}
                  </span>
                </Div>

                <Div>
                  SOFTWARE VERSION:{" "}
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {selectedSWVersion.value}
                  </span>
                </Div>

                <Div>
                  CODE NUMBER:{" "}
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {selectedCodeNumber.value}
                  </span>
                </Div>
              </Div>
            </Div>
            <img
              src={`${IMAGE_BASE64}${controllerImg}`}
              alt=""
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-110px",
                marginLeft: "960px",
                right: "100px",
                width: "200px",
                height: "120px",
                pointerEvents: "none",
              }}
            />
            <CommandBar
              items={[
                {
                  key: "1",
                  text: "PARAMETERS",
                  disabled: false,
                  styles: {
                    root: {
                      border: "3px solid black",
                      borderRight: "0px",
                      borderLeft: "0px",
                      borderTop: "0px",
                      fontWeight: "bold",
                      color: "black !important",
                    },
                  },
                },
                {
                  key: "2",
                  onRender: () => (
                    <Button
                      small={true}
                      onClick={handleClickSave}
                      iconProps={{ glyph: icons.SAVE }}
                      styles={{
                        root: {
                          height: "35px",
                          top: "13.5px",
                          color: "black !important",
                          left: "600px",
                        },
                      }}
                    >
                      {SAVE_TEXT}
                    </Button>
                  ),
                },
                {
                  key: "3",
                  onRender: () => <SaveAsPopup saveAsvalue={saveFileValue} />,
                },
                {
                  key: "4",
                  onRender: () => <DownloadPopup />,
                },
                {
                  key: "5",
                  onRender: () => <Upload />,
                },
              ]}
              styles={{
                root: { borderTop: "0.5px solid #dee2e6", mt: "30px" },
              }}
            />
            <Div style={{ overflow: "auto", maxHeight: "700px" }}>
              {treeTableData ? <TreeTable ParamData={treeTableData} /> : null}
            </Div>
          </>
        }
      />
      {loading && (
        <Div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Spinner color="#34495E" />
          <H6 style={{ color: "#34495E", marginTop: "8px" }}>Please Wait</H6>
        </Div>
      )}
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openInfoPopup}
        onClose={handleInfoClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={INVALID_VALUE_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleInfoClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {OK_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "115px",
            },
          }}
        ></Icon>
        <P textAlign={"center"} style={{ marginLeft: "20px" }}>
          {withinMinMax && integerValue
            ? `${INFO_INTEGER_TEXT}\n ${INVALID_INPUT_MESSAGE}`
            : withinMinMax
            ? INVALID_INPUT_MESSAGE
            : integerValue
            ? INFO_INTEGER_TEXT
            : ""}
        </P>
      </Modal>
    </Div>
  );
};
