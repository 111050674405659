import { Button, H6, Icon, Modal, Spinner, icons } from "@danfoss/etui-core";
import { Div, P } from "@danfoss/etui-system-elements";
import { useLayout } from "../components/context/layoutConfig";
import {
  FINISH_TEXT,
  NEW_PROJECT_PATH,
  REPLACE,
  REPLACE_MESSAGE,
  SUCCESS_TEXT,
  FILE_CREATED_MESSAGE,
  FILE_EXIST_MESSAGE,
  JSON_EXTENSION,
  NO_TEXT,
  REPLACE_PROJECT_TEXT,
  REPLACED_MESSAGE,
  YES_Text,
  WAIT_TEXT,
} from "../constants";
import { useNavigate } from "react-router-dom";
import { getNewProjectData } from "../Services/newProjectApi";
import { useEffect, useState } from "react";
import {
  ParameterType,
  newProjectDataType,
  newProjectParameter,
} from "../types";
import { getDefaultData } from "../utils";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";

export const FinishPopup = () => {
  const [openFinishPopup, setOpenFinishPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    disableFinish,
    activeStep,
    newProjectName,
    selectedCdfFile,
    selectedSWVersion,
    selectedDeviceName,
    newProjectDescription,
    selectedCodeNumber,
    setOpenPopup,
    setActiveStep,
    setdisableNext,
    setDisableFinish,
    defaultProjectData,
    setDefaultProjectData,
    controllerFamily,
    setUploadNameWithType,
    isElectronApp,
  } = useLayout();

  useEffect(() => {
    if (!openFinishPopup) {
      setLoading(false);
    }
  }, [openFinishPopup]);

  if (activeStep === 1) {
    setDisableFinish(true);
  }
  if (activeStep === 2 && newProjectName === "") {
    setDisableFinish(true);
  }

  const navigate = useNavigate();
  const NewProjectRoute = () => {
    let path: string = NEW_PROJECT_PATH;
    navigate(path);
  };

  const handleFinishOpen = () => {
    if (activeStep === 2) {
      setOpenFinishPopup(true);
    } else {
      setOpenPopup(false);
      setdisableNext(true);
    }
  };

  const handleFinishClose = () => {
    setOpenFinishPopup(false);
  };

  const handleClose = async () => {
    setLoading(true);
    const replaceRes: string = await getNewProjectData(
      newProjectName,
      defaultProjectData,
      true,
      isElectronApp
    );
    setUploadNameWithType(newProjectName + JSON_EXTENSION);
    if (replaceRes === REPLACED_MESSAGE) {
      setOpenPopup(false);
      if (activeStep > 1) {
        setActiveStep(activeStep - 1);
      }
      setdisableNext(true);
      NewProjectRoute();
      Notification.success({
        message: SUCCESS_TEXT,
        description: REPLACED_MESSAGE,
        duration: 3,
        theme: defaultTheme,
      });
    }
  };

  const handleFinish = async () => {
    setLoading(true);
    let parameters: newProjectParameter[] = [];
    selectedCdfFile?.cdfData?.device?.Parameters.map(
      (parameter: ParameterType) => {
        let expoData: number = -1;
        if (parameter?.Decimals === 0) {
          expoData = 1;
        } else {
          if (parameter?.Decimals > 0) {
            expoData = 10 ** parameter?.Decimals;
          }
        }
        const param: newProjectParameter = {
          expo: expoData,
          PNU: parameter?.Vid,
          menucode: parameter?.Label,
          parameter: parameter?.Name,
          value: getDefaultData(parameter),
        };
        return parameters.push(param);
      }
    );

    const projectData: newProjectDataType = processNewProjectData(parameters);
    const newProjectRes = await getNewProjectData(
      newProjectName,
      projectData,
      false,
      isElectronApp
    );
    if (newProjectRes) {
      if (newProjectRes === FILE_EXIST_MESSAGE) {
        handleFinishOpen();
      } else {
        if (newProjectRes === FILE_CREATED_MESSAGE) {
          setOpenPopup(false);
          setUploadNameWithType(newProjectName + JSON_EXTENSION);
          if (activeStep > 1) {
            setActiveStep(activeStep - 1);
          }
          setdisableNext(true);
          NewProjectRoute();
        }
      }
    }
  };

  const processNewProjectData = (
    defaultParam: newProjectParameter[]
  ): newProjectDataType => {
    const newProjectData: newProjectDataType = {
      CNF: {
        Name2: selectedSWVersion?.value,
        Name1: selectedDeviceName,
        Name0: controllerFamily,
        Description: newProjectDescription,
        CONFIGURATION: {
          SET: defaultParam,
          name: newProjectName,
          version: "",
        },
        ProductSpecificInfo: {
          CodeNumber: selectedCodeNumber?.value,
          SWVersion: selectedSWVersion?.value,
        },
      },
    };
    setDefaultProjectData(newProjectData);
    return newProjectData;
  };
  return (
    <Div>
      {activeStep === 2 && (
        <Button
          variant="primary"
          textCenter={true}
          onClick={handleFinish}
          disabled={disableFinish}
          styles={{
            root: { mr: "0.5rem", height: "30px", width: "80px" },
          }}
        >
          {FINISH_TEXT}
        </Button>
      )}
      {loading && (
        <Div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Spinner color="#34495E" />
          <H6 style={{ color: "#34495E", marginTop: "8px" }}>{WAIT_TEXT}</H6>
        </Div>
      )}
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openFinishPopup}
        onClose={handleFinishClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={REPLACE_PROJECT_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            {activeStep === 2 && (
              <Button
                variant="primary"
                textCenter={true}
                onClick={handleClose}
                disabled={disableFinish}
                styles={{
                  root: { mr: "0.5rem", height: "30px", width: "80px" },
                }}
              >
                {YES_Text}
              </Button>
            )}
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleFinishClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {NO_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "110px",
            },
          }}
        ></Icon>
        <P textAlign={"center"}>{REPLACE}</P>
        <P textAlign={"center"}> {REPLACE_MESSAGE}</P>
      </Modal>
    </Div>
  );
};
