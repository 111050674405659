import { ElementCompact, xml2js } from "xml-js";
import {
  configurationData,
  newProjectDataType,
  newProjectData,
  productInfoData,
} from "../types";
import { REPLACE_TYPE } from "../constants";

export const xmlToJson = (
  xmlData: string,
  fileName: string
): newProjectData => {
  const parsedData: ElementCompact = xml2js(xmlData, { compact: true });

  const projectName: string = fileName.replace(REPLACE_TYPE, "");
  const configuration: configurationData = {
    SET: parsedData?.CNF?.CONFIGURATION?.SET?.map((param: ElementCompact) => ({
      expo: Number(param?._attributes?.expo),
      PNU: Number(param?._attributes?.PNU),
      menucode: param?._attributes?.menucode,
      parameter: param?._attributes?.parameter,
      value: Number(param?._text),
    })),
    name: parsedData?.CNF?.CONFIGURATION?._attributes?.name,
    version: parsedData?.CNF?.CONFIGURATION?._attributes?.version,
  };

  const productInfoData: productInfoData = {
    CodeNumber: parsedData?.CNF?.ProductSpecificInfo?.CodeNumber?._text,
    SWVersion: parsedData?.CNF?.ProductSpecificInfo?.SWVersion?._text,
  };

  const projectData: newProjectDataType = {
    CNF: {
      Name2: parsedData?.CNF?.Name2?._text,
      Name1: parsedData?.CNF?.Name1?._text,
      Name0: parsedData?.CNF?.Name0?._text,
      Description: parsedData?.CNF?.Description?._text,
      CONFIGURATION: configuration,
      ProductSpecificInfo: productInfoData,
    },
  };

  const newProjectData: newProjectData = {
    projectName: projectName,
    projectData: projectData,
  };

  return newProjectData;
};
