import {
  Button,
  Form,
  H6,
  Icon,
  Input,
  Modal,
  icons,
} from "@danfoss/etui-core";
import { Div, P } from "@danfoss/etui-system-elements";
import { getUploadData } from "../Services/uploadApi";
import { useLayout } from "../components/context/layoutConfig";
import {
  CANCEL_TEXT,
  REPLACE,
  RENAME_TEXT,
  REPLACE_TEXT,
  SUCCESS_TEXT,
  UPLOAD_TEXT,
  FILE_EXIST_MESSAGE,
  FILE_NAME_TEXT,
  JSON_EXTENSION,
  REPLACED_MESSAGE,
  UPLOADED_MESSAGE,
  XML_EXTENSION,
  RENAME_FILE_TEXT,
  PROJECT_EXIST_TEXT,
} from "../constants";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import { useRef, useState } from "react";
import { eventData, newProjectData } from "../types";
import { xmlToJson } from "../utils";

export const Upload = () => {
  const { isOnline, isElectronApp, uploadNameWithType } = useLayout();
  const [openUploadPopup, setOpenUploadPopup] = useState<boolean>(false);
  const [openRenamePopup, setOpenRenamePopup] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [fileContent, setFileContent] = useState<string | newProjectData>("");
  const uploadFileRef = useRef<HTMLInputElement>(null);
  
  const handleClickUpload = async () => {
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };
  const handleClose = async () => {
    setOpenUploadPopup(false);
  };

  const handleReplace = async () => {
    const uploadRes: string = await getUploadData(
      uploadNameWithType,
      true,
      false,
      "",
      isElectronApp,
      fileContent
    );
    setOpenUploadPopup(false);
    if (uploadRes === REPLACED_MESSAGE) {
      Notification.success({
        message: SUCCESS_TEXT,
        description: REPLACED_MESSAGE,
        duration: 3,
        theme: defaultTheme,
      });
    }
  };

  const handleRename = () => {
    setOpenRenamePopup(true);
    if (uploadNameWithType.endsWith(JSON_EXTENSION)) {
      setFileType(JSON_EXTENSION);
    } else {
      if (uploadNameWithType.endsWith(XML_EXTENSION)) {
        setFileType(XML_EXTENSION);
      }
    }
  };
  const handleRenameClose = () => {
    setOpenRenamePopup(false);
  };
  const handleRenameFile = async () => {
    setOpenRenamePopup(false);
    setOpenUploadPopup(false);
    const renamewithType: string = fileName + fileType;
    const uploadRes: string = await getUploadData(
      uploadNameWithType,
      false,
      true,
      renamewithType,
      isElectronApp,
      fileContent
    );
    if (uploadRes === UPLOADED_MESSAGE) {
      Notification.success({
        message: SUCCESS_TEXT,
        description: UPLOADED_MESSAGE,
        duration: 3,
        theme: defaultTheme,
      });
    } else if (uploadRes === FILE_EXIST_MESSAGE) {
      setOpenUploadPopup(true);
    }
  };
  const handleFileName = (e: eventData) => {
    setFileName(e?.target.value);
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event?.target?.files;
    if (fileList) {
      const file = fileList[0];
      const fileName: string = file.name;
      const fileBlob: Blob = new Blob([file]);
      const text: string = await fileBlob.text();

      if (fileName.endsWith(JSON_EXTENSION)) {
        try {
          const json: string = JSON.parse(text);
          setFileContent(json);
          const uploadRes: string = await getUploadData(
            fileName,
            false,
            false,
            "",
            isElectronApp,
            json
          );
          if (uploadRes === UPLOADED_MESSAGE) {
            Notification.success({
              message: SUCCESS_TEXT,
              description: UPLOADED_MESSAGE,
              duration: 3,
              theme: defaultTheme,
            });
          } else if (uploadRes === FILE_EXIST_MESSAGE) {
            setOpenUploadPopup(true);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } else if (fileName.endsWith(XML_EXTENSION)) {
        try {
          const xmlData: newProjectData = xmlToJson(text, fileName);
          setFileContent(xmlData);
          const uploadRes: string = await getUploadData(
            fileName,
            false,
            false,
            "",
            isElectronApp,
            xmlData
          );
          if (uploadRes === UPLOADED_MESSAGE) {
            Notification.success({
              message: SUCCESS_TEXT,
              description: UPLOADED_MESSAGE,
              duration: 3,
              theme: defaultTheme,
            });
          } else if (uploadRes === FILE_EXIST_MESSAGE) {
            setOpenUploadPopup(true);
          }
        } catch (error) {
          console.error("Error parsing XML:", error);
        }
      } else {
        console.error("Error in file type");
      }
    }
    if (uploadFileRef.current) {
      uploadFileRef.current.value = "";
    }
  };
  return (
    <Div>
      <Button
        small={true}
        iconProps={{ glyph: icons.UPLOAD }}
        onClick={handleClickUpload}
        disabled={!isOnline}
        styles={{
          root: {
            height: "35px",
            top: "13.5px",
            color: isOnline ? "black !important" : "#D8D8D9",
            left: "603px",
          },
        }}
      >
        {UPLOAD_TEXT}
      </Button>
      <input
        type="file"
        ref={uploadFileRef}
        accept=".xml , .json"
        style={{ display: "none" }}
        onChange={handleFileUpload}
        multiple={false}
      />
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openUploadPopup}
        onClose={handleClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={PROJECT_EXIST_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleReplace}
              styles={{
                root: { mr: "0.5rem", height: "30px", width: "80px" },
              }}
            >
              {REPLACE_TEXT}
            </Button>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleRename}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {RENAME_TEXT}
            </Button>
          </Div>
        }
      >
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: "orange",
              width: "50px",
              height: "50px",
              ml: "110px",
            },
          }}
        ></Icon>
        <P textAlign={"center"}>{REPLACE}</P>
      </Modal>
      <Modal
        style={{
          content: { maxWidth: "500px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "130px", width: "300px" } }}
        fullPageSize={false}
        isOpen={openRenamePopup}
        onClose={handleRenameClose}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={RENAME_FILE_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleRenameFile}
              styles={{
                root: { mr: "0.5rem", height: "30px", width: "90px" },
              }}
            >
              {RENAME_TEXT}
            </Button>
            <Button
              variant="primary"
              textCenter={true}
              onClick={handleRenameClose}
              styles={{
                root: { width: "80px", height: "30px" },
              }}
            >
              {CANCEL_TEXT}
            </Button>
          </Div>
        }
      >
        <Form styles={{ root: { pl: "20px", pr: "20px", pt: "10px" } }}>
          <H6>{FILE_NAME_TEXT}</H6>
          <Input
            type="text"
            size="small"
            placeholder={FILE_NAME_TEXT}
            onChange={(e) => {
              handleFileName(e);
            }}
          ></Input>
        </Form>
      </Modal>
    </Div>
  );
};
