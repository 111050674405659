import { REGEX_SPECIALCHAR, REGEX_UNSUPPORTED_INPUT } from "../constants";

export const fileNameValid = (text: string) => {
  const pattern = REGEX_SPECIALCHAR;
  return pattern.test(text) ? false : true;
};
export const parameterValueValidation = (
  text: string,
  min: number,
  max: number
) => {
  const pattern = REGEX_UNSUPPORTED_INPUT;
  if (!pattern.test(text)) {
    return true;
  }
  const value = Number(text);
  return value < min || value > max;
};
export const validateIntegers = (text: string, decimalValue: number) => {
  if (decimalValue === 0) {
    return text.includes(".");
  }
  return false;
};
