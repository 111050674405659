import { fetchData } from "../action/fetch";
import { NEW_PROJECT_URL } from "../constants";
import { newProjectDataType } from "../types";

export const getNewProjectData = async (
  projectName: string,
  projectData: newProjectDataType,
  replaceProject: boolean,
  isElectron: boolean
) => {
  try {
    const newProjecturl: string =
      process.env.APPSETTING_CDF_URL || NEW_PROJECT_URL;
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      mode: "no-cors",
    };
    const newProjectApi = await fetchData(newProjecturl, {
      newProjectName: projectName,
      newProjectData: projectData,
      replaceProject: replaceProject,
      isElectron: isElectron,
      headers,
    });
    return newProjectApi.data?.message;
  } catch (error) {
    console.error("Error new Project store API response:", error);
  }
};
