import { BrandLogo } from "@danfoss/etui-core";
import { useEffect, useState } from "react";
import { Div } from "@danfoss/etui-system-elements";
import { LOADER_MESSAGE } from "../constants";
import HomePage from "./HomePage";
const LoadingPage = () => {
  const [LoadingPage, setLoadingPage] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 4000);
  }, []);
  return (
    <Div style={{ overflow: "hidden" }}>
      {LoadingPage ? (
        <Div>
          <>
            <Div
              style={{
                background: "#E2000F",
                height: "960px",
              }}
            >
              <BrandLogo
                showSlogan={true}
                shadow={true}
                styles={{
                  root: {
                    bg: "transparent",
                    height: "90px",
                    p: `${8}px`,
                  },
                }}
              />
              <h1
                style={{
                  color: "#FFFF",
                  textAlign: "center",
                  marginTop: "250px",
                }}
              >
                {LOADER_MESSAGE}
              </h1>
            </Div>
          </>
        </Div>
      ) : (
        <HomePage />
      )}
    </Div>
  );
};
export default LoadingPage;
