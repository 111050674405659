import * as crc from "crc";
import * as Buffer from "buffer";
import { FIRSTBYTE } from "../constants";

export const UsbRequestData = async (
  pnu: number,
  modbusFunction: string,
  deviceAddress: string,
  value: number
): Promise<string[]> => {
  let requestArray: string[] = [];
  try {
    const pnuData: number = pnu - 1;
    const pnuHexData: string = pnuData.toString(16).padStart(2, "0");
    const hexValueData: string = value.toString(16).padStart(2, "0");
    const formattedPnuData: string =
      pnuHexData.length < 4
        ? FIRSTBYTE.repeat(4 - pnuHexData.length) + pnuHexData
        : pnuHexData;
    const formattedValueData: string =
      hexValueData.length < 4
        ? FIRSTBYTE.repeat(4 - hexValueData.length) + hexValueData
        : hexValueData;
    const requestFinalStructure: string[] = [];
    const slaveAddress: string = deviceAddress;
    const functionCode: string = `0x${modbusFunction
      .toString()
      .padStart(2, "0")}`;
    const firstDataAddress: string = `0x${formattedPnuData
      .toString()
      .slice(0, 2)}`;
    const secondDataAddress: string = `0x${pnuHexData.toString().slice(-2)}`;
    const firstValue: string = `0x${formattedValueData.toString().slice(0, 2)}`;
    const secondValue: string = `0x${hexValueData.toString().slice(-2)}`;
    requestFinalStructure.push(
      slaveAddress,
      functionCode,
      firstDataAddress,
      secondDataAddress,
      firstValue,
      secondValue
    );

    const numericData: number[] = requestFinalStructure.map(
      (hexString: string) => parseInt(hexString, 16)
    );
    const bufferData = Buffer.Buffer.from(numericData);
    const crc16Data: string = crc
      .crc16modbus(bufferData)
      .toString(16)
      .toUpperCase();
    const formattedCrc: string =
      crc16Data.length < 4
        ? FIRSTBYTE.repeat(4 - crc16Data.length) + crc16Data
        : crc16Data;
    const firstCrcData: string = formattedCrc.slice(0, 2);
    const secondCrcData: string = formattedCrc.slice(2);
    const formattedFirstCrcData: string = `0x${secondCrcData}`;
    const formattedSecondCrcData: string = `0x${firstCrcData}`;

    requestFinalStructure.push(formattedFirstCrcData, formattedSecondCrcData);
    requestArray = requestFinalStructure;
  } catch (e) {
    console.log("Error in forming request structure:", e);
  }
  return requestArray;
};
