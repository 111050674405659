import { usbFilters } from "../types/controllerData.types";
import { Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import {
  PORT_CONFIG,
  PORT_CONNECTED_STATUS,
  PORT_NOT_CONNECTED_STATUS,
} from "../constants";

export const usbPortConnection = async () => {
  const filters: usbFilters[] = [{ usbVendorId: 7750 }, { usbVendorId: 1659 }];
  try {
    await navigator.serial.requestPort({ filters });
    const ports = await navigator.serial.getPorts();

    const filteredPorts = ports.filter((port) => {
      const usbDevice = port.getInfo();
      return (
        usbDevice &&
        filters.some((filter) => usbDevice.usbVendorId === filter.usbVendorId)
      );
    });
    const connectedPortLength: number = filteredPorts.length;
    if (connectedPortLength !== 0) {
      const enabledPort: SerialPort = await enableUsbPort(filteredPorts);
      return { status: PORT_CONNECTED_STATUS, data: enabledPort };
    } else {
      Notification.error({
        message: "Error",
        description: "No USB device connected",
        duration: 3,
        theme: defaultTheme,
      });
      return { status: PORT_NOT_CONNECTED_STATUS };
    }
  } catch (error) {
    Notification.error({
      message: "Error",
      description: "No USB device selected by the user",
      duration: 3,
      theme: defaultTheme,
    });
    console.error("Error in serial port connection:", error);
    return { status: PORT_NOT_CONNECTED_STATUS };
  }
};
export const enableUsbPort = async (
  filteredPorts: SerialPort[]
): Promise<SerialPort> => {
  try {
    const selectedPort: SerialPort = filteredPorts[0];
    Notification.success({
      message: "Success",
      description: "Device connected successfully",
      duration: 3,
      theme: defaultTheme,
    });
    await selectedPort.open({
      baudRate: PORT_CONFIG.BAUDRATE.valueOf(),
      parity: PORT_CONFIG.PARITY,
    });
    return selectedPort;
  } catch (e) {
    console.error("Error in connecting port: ", e);
    throw e;
  }
};
