import { Route, Routes } from "react-router-dom";
import { CDFPage } from "./CDFPage";
import { OfflineProject } from "./OfflineProjectPage";
import { Div } from "@danfoss/etui-system-elements";
import View from "./View";
import { DefaultPage } from "./Default";
import { MainData } from "../types/controllerData.types";
import { ProjectCreationPage } from "./ProjectCreationPage";

const Routing = ({
  data,
  handleconnection,
}: {
  data: MainData;
  handleconnection: () => Promise<void>;
}) => {
  return (
    <Div>
      <Routes>
        <Route
          path="/"
          element={<DefaultPage handleconnection={handleconnection} />}
        />
        <Route path="/cdf" element={<CDFPage />} />
        <Route path="/SetParameter" element={<View data={data} />} />
        <Route path="/Offline" element={<OfflineProject />} />
        <Route path="/NewProject" element={<ProjectCreationPage />} />
        <Route path="/OpenProject" element={<ProjectCreationPage />} />
      </Routes>
    </Div>
  );
};

export default Routing;
