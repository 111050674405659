import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Routing from "./Routing";
import { getControllerData } from "../Services/controllerApi";
import { Div } from "@danfoss/etui-system-elements";
import {
  CDF,
  IDENTIFY_DEVICE_API,
  READ_DEVICE_ADDRESS,
  READ_DEVICE_API,
  SETDEFAULT,
  SETPARAMETER,
  SETPARAMNAME,
  OFFLINE,
  NEWPROJECT,
} from "../constants";
import { Modal, Notification } from "@danfoss/etui-core";
import { defaultTheme } from "@danfoss/etui-themes";
import useSWR from "swr";
import {
  icons,
  SideMenuGroupProps,
  Header,
  HeaderBrandLogo,
  SideMenu,
} from "@danfoss/etui-core";
import {
  HostProps,
  IdentificationData,
  MainData,
  ParameterInfo,
  PnuValues,
  UsbConnection,
  UsbResult,
  allControllerInfo,
  controllerInfoData,
} from "../types/controllerData.types";
import { usbPortConnection } from "../utils/usbConnection";
import { usbDeviceIdentification } from "../utils/deviceIdentification";
import { fetchControllerData } from "../utils";
import { useLayout } from "../components/context/layoutConfig";
import { PreferencePage } from "./PreferencePage";
import { getControllerInfo } from "../Services/controllerInfoApi";

export const HomePage = () => {
  const {
    isdisplayParam,
    setisdisplayParam,
    path,
    setPath,
    selectedAddress,
    setOpenSettings,
  } = useLayout();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [deviceResponseData, setDeviceResponseData] =
    useState<IdentificationData>();
  const [menu, setMenu] = useState<MainData>({
    engunittypes: [],
    enumerations: [],
    groups: [],
    info: {
      cdfId: "",
      codeNumber: "",
      name: "",
      softwareVersion: 0,
      variant: "",
    },
    parameters: [],
    pnuValues: [],
    usbConnection: null,
  });
  const [parameterdata, setParameterData] = useState<ParameterInfo>({
    engunittypes: [],
    enumerations: [],
    groups: [],
    parameters: [],
  });
  const [controllerInformation, setControllerInformation] =
    useState<allControllerInfo>({
      info: {
        cdfId: "",
        codeNumber: "",
        name: "",
        softwareVersion: 0,
        variant: "",
      },
    });
  const [usbDevice, setUsbDevice] = useState<SerialPort | any>();

  const { setControllerInfoData, setOpenProject, setDisableDone } = useLayout();

  useEffect(() => {
    const fetchControllerInfo = async () => {
      try {
        const controllerDetails: controllerInfoData[] =
          await getControllerInfo();
        setControllerInfoData(controllerDetails);
      } catch (error) {
        console.error("Error fetching controller info and image:", error);
      }
    };
    fetchControllerInfo();
  }, [setControllerInfoData]);

  const handleSerialConnection = async () => {
    try {
      const usbEnabled: UsbResult = await usbPortConnection();
      const usbData: SerialPort = usbEnabled?.data;
      if (usbData) {
        setUsbDevice(usbData);
        setisdisplayParam(false);
      }
      console.log("USB Enabled:", usbData);
    } catch (error) {
      console.error("Error in usbConnection of Home Page:", error);
    }
  };

  const navigate = useNavigate();
  let CDFID: string;

  const fetchModbusData = async () => {
    const deviceAddress: string = selectedAddress?.value;
    const finalData: string = await usbDeviceIdentification(
      usbDevice,
      deviceAddress
    );
    const host: HostProps = { host: IDENTIFY_DEVICE_API, id: finalData };
    try {
      const controllerInfo: IdentificationData = await getControllerData(host);
      const exists: boolean =
        controllerInfo &&
        Object.keys(controllerInfo).includes("controllerData");

      if (exists === true) {
        const cdfId: string = controllerInfo?.controllerData?.cdfId;
        CDFID = cdfId;
        setDeviceResponseData(controllerInfo);
        setParameterData(controllerInfo?.parameterData);
        const finalInfoData: allControllerInfo = {
          info: controllerInfo?.controllerData,
        };
        setControllerInformation(finalInfoData);
      } else {
        const value: string = controllerInfo?.message;
        Notification.error({
          message: "Error",
          description: value,
          duration: 3,
          theme: defaultTheme,
        });
      }
    } catch (error) {
      console.log("Error in Device Identification: ", error);
    }
  };

  const {
    data: updatedValues,
    error,
    mutate,
  } = useSWR(
    "/controller",
    () =>
      deviceResponseData
        ? fetchControllerData(
            { host: READ_DEVICE_API, id: CDFID },
            READ_DEVICE_ADDRESS,
            usbDevice,
            selectedAddress
          )
        : null,
    {
      refreshInterval: 2000,
      onError: (error) => {
        console.error("Polling error:", error);
        setTimeout(() => {
          mutate();
        }, 2000);
      },
    }
  );
  useEffect(() => {
    if (error) {
      console.log("Error in updatedValues:", error);
    }

    if (updatedValues) {
      process03Data(parameterdata, updatedValues, controllerInformation);
    }
  }, [updatedValues, error, controllerInformation, parameterdata]);

  const process03Data = (
    ParameterData: ParameterInfo,
    pnuValues: PnuValues,
    controllerData: allControllerInfo
  ) => {
    const usbConnection: UsbConnection = {
      usbConnection: usbDevice,
    };
    const mergedObj: MainData = Object.assign(
      {},
      ParameterData,
      pnuValues,
      controllerData,
      usbConnection
    );
    setMenu(mergedObj);
  };

  const routeChange = async () => {
    let path: string = SETPARAMETER;
    navigate(path);
    setPath(SETPARAMNAME);
    fetchModbusData();
  };

  const cdfRoute = () => {
    let path: string = CDF;
    navigate(path);
    setPath(CDF);
  };

  const OfflineRoute = () => {
    let path: string = OFFLINE;
    navigate(path);
    setPath(OFFLINE);
    setOpenProject(false);
  };

  const location = useLocation();

  const handleClickOpen = () => {
    setOpenSettings(true);
    setDisableDone(true);
  };

  useEffect(() => {
    const pathName: string = location.pathname;

    if (pathName === "/SetParameter") {
      setPath(SETPARAMNAME);
    }
    if (pathName === "/") {
      setPath(SETDEFAULT);
    }
    if (pathName === "/Offline") {
      setPath(OFFLINE);
    }
    if (pathName === "/NewProject") {
      setPath(NEWPROJECT);
    }
  }, [location.pathname]);
  const getSideMenuNoGroups = (): SideMenuGroupProps[] => [
    {
      items: [
        {
          icon: { glyph: icons.CONTROLS },
          title: SETPARAMNAME,
          disabled: isdisplayParam,
          onClick: () => {
            routeChange();
          },
        },

        {
          icon: { glyph: icons.DB },
          title: CDF,
          onClick: () => {
            cdfRoute();
          },
        },
        {
          icon: { glyph: icons.REPORTS },
          title: OFFLINE,
          onClick: () => {
            OfflineRoute();
          },
        },
      ],
    },
  ];
  return (
    <Div>
      <Header
        logo={<HeaderBrandLogo />}
        topMenuItems={[
          {
            iconProps: { glyph: icons.HOME },
            iconOnly: true,
            title: "home",
            styles: {
              root: {
                position: "fixed",
                left: "120px",
              },
            },
          },
          {
            iconProps: { glyph: icons.COG },
            title: "Settings",
            iconOnly: true,
            onClick: handleClickOpen,
          },
        ]}
        bottomMenuItems={[
          {
            title: `${path}`,
            active: true,
          },
        ]}
        hasSideMenu={true}
        toggleSideMenu={() => {
          setIsSideMenuOpen(true);
        }}
      />

      <SideMenu
        isSideMenuOpen={isSideMenuOpen}
        groups={getSideMenuNoGroups()}
        closeSideMenu={() => {
          setIsSideMenuOpen(false);
        }}
        toggleSideMenu={() => setIsSideMenuOpen(false)}
      />
      <PreferencePage />

      <Routing data={menu} handleconnection={handleSerialConnection} />
    </Div>
  );
};

export default HomePage;
