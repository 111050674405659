import { Div } from "@danfoss/etui-system-elements";
import { SelectedValueType, TreeItem, TreeView } from "@danfoss/etui-core";
import { useLayout } from "../components/context/layoutConfig";
import React from "react";
import { MainTreeItem } from "../components/components/components";
import { EKC_NAME_TEXT, EKC_TEXT, EKE_NAME_TEXT, EKE_TEXT } from "../constants";

export const OfflineDevice = (offlineData: TreeItem[]) => {
  const {
    selectedDeviceName,
    setSelectedDeviceName,
    setdisableNext,
    setControllerFamily,
  } = useLayout();
  const [showDefaultMenu, setShowDefaultMenu] = React.useState<boolean>(true);
  const [openSections, setOpenSections] = React.useState<SelectedValueType[]>(
    []
  );
  React.useEffect(() => {
    setShowDefaultMenu(false);
  }, []);
  React.useEffect(() => {
    const controllerName: string = deviceFamilyName();
    if (controllerName) {
      setControllerFamily(controllerName);
    }
  }, [selectedDeviceName]);
  const selectedRow = (item: TreeItem) => {
    const varientName: string = item?.name;
    setSelectedDeviceName(varientName);
    if (selectedDeviceName === "") {
      setdisableNext(false);
    }
  };
  React.useEffect(() => {
    if (showDefaultMenu && offlineData.length) {
      let sectionsIds = [];
      sectionsIds.push(offlineData.at(0)?.id);
      sectionsIds.push(offlineData.at(0)?.items?.at(0)?.id);
      sectionsIds.push(offlineData.at(0)?.items?.at(0)?.items?.at(0)?.id);
      setOpenSections(sectionsIds);
    }
  }, [offlineData?.length]);

  const handleOpenSectionChange = (
    sectionsOpenUpdated: SelectedValueType[]
  ) => {
    setOpenSections(sectionsOpenUpdated);
  };
  const deviceFamilyName = () => {
    const name: string = selectedDeviceName.slice(0, 3);
    return name === EKC_TEXT
      ? EKC_NAME_TEXT
      : name === EKE_TEXT
      ? EKE_NAME_TEXT
      : name;
  };
  return (
    <Div>
      <TreeView
        data={offlineData}
        onRowSelect={selectedRow}
        onOpenSectionChange={handleOpenSectionChange}
        openSections={openSections}
        styles={{
          root: {
            fontWeight: 700,
            height: "420px",
            width: "270px",
            overflowY: "auto",
          },
          row: {
            root: {
              fontWeight: 700,
            },
          },
        }}
        rowTemplate={(props: any) => (
          <MainTreeItem key={props.item.name} {...props} />
        )}
      />
    </Div>
  );
};
