import axios from "axios";

export const fetchData = async (url: string, req: any) => {
  const res = await axios.post(`${url}`, req);
  return res;
};

export const fetchCdfData = async (url: string) => {
  const res = await axios.get(`${url}`);
  return res;
};