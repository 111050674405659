import {
  READ_DEVICE_ADDRESS,
  USB_REQRES_TIMEOUT,
  USB_RESPONSE_TIMEOUT,
  WRITE_DEVICE_ADDRESS,
} from "../constants";
import { deviceAddress, finalReadValue } from "../types/controllerData.types";
import { readComplements } from "./readComplement";
import { readUsbData } from "./readUsb";
import { UsbRequestData } from "./usbRequest";
import { extractHexValues } from "./usbResponse";
import { writeUsbData } from "./writeUsb";
import * as Buffer from "buffer";

export const dataWrite = async (
  vidData: number,
  vidValue: number,
  vidDecimal: number,
  connection: SerialPort,
  selectedAddress: deviceAddress
) => {
  let writeResponseValue: finalReadValue = {
    updatedRead: 0,
  };
  try {
    const writeValue: Buffer = await writeDeviceData(
      vidData,
      vidValue,
      connection,
      selectedAddress
    );
    if (writeValue) {
      await new Promise((resolve) => setTimeout(resolve, USB_RESPONSE_TIMEOUT));
      const finalReadData: finalReadValue = await readingWriteData(
        vidData,
        vidDecimal,
        connection,
        selectedAddress
      );
      writeResponseValue = finalReadData;
    } else {
      console.error("No write value for read");
    }
  } catch (e) {
    console.log("Error in writing text or combo values:", e);
  }
  return writeResponseValue;
};

export const readingWriteData = async (
  vidData: number,
  vidDecimal: number,
  connection: SerialPort,
  selectedAddress: deviceAddress
): Promise<finalReadValue> => {
  let finalScaledValue: finalReadValue = { updatedRead: 0 };
  try {
    const dAddress = selectedAddress?.value;
    const functionCode: string = READ_DEVICE_ADDRESS;
    const requestData: string[] = await UsbRequestData(
      vidData,
      functionCode,
      dAddress,
      1
    );
    const responseRead: number = await readDeviceDatas(requestData, connection);
    const finalreadData: number = readComplements(responseRead);

    const scaledReaddata: number =
      vidDecimal === 1 ? finalreadData / 10 : responseRead;
    finalScaledValue = { updatedRead: scaledReaddata };
  } catch (e) {
    console.error("Error in Reading 06 command Data: ", e);
  }
  return finalScaledValue;
};
export const readDeviceDatas = async (
  readData: string[],
  connection: SerialPort
): Promise<number> => {
  let hexValue: number = 0;
  let buffer: Uint8Array = new Uint8Array(0);
  try {
    const readRequestData: Uint8Array = new Uint8Array(
      readData.map((hex: string) => parseInt(hex, 16))
    );
    writeUsbData(connection, readRequestData);

    await new Promise((resolve) => setTimeout(resolve, USB_REQRES_TIMEOUT));
    const result: Buffer.Buffer = await readUsbData(
      connection,
      USB_RESPONSE_TIMEOUT
    );
    buffer = new Uint8Array([...buffer, ...result]);
    while (buffer.length >= 7) {
      const readUintData: Uint8Array = buffer.slice(0, 7);
      const readHexData: number = extractHexValues(readUintData);
      hexValue = readHexData;
      buffer = buffer.slice(7);
    }
  } catch (error) {
    console.error("Error in sending request to write:", error);
  }
  return hexValue;
};
export const writeDeviceData = async (
  vidData: number,
  vidValue: number,
  connection: SerialPort,
  selectedAddress: deviceAddress
): Promise<Buffer> => {
  let finalWriteValue: Buffer = Buffer.Buffer.from([]);
  try {
    const functionCode: string = WRITE_DEVICE_ADDRESS;
    const dAddress = selectedAddress?.value;
    const requestData: string[] = await UsbRequestData(
      vidData,
      functionCode,
      dAddress,
      vidValue
    );
    const readRequestData: Uint8Array = new Uint8Array(
      requestData.map((hex: string) => parseInt(hex, 16))
    );
    writeUsbData(connection, readRequestData);
    await new Promise((resolve) => setTimeout(resolve, USB_REQRES_TIMEOUT));
    const result: Buffer.Buffer = await readUsbData(
      connection,
      USB_RESPONSE_TIMEOUT
    );
    finalWriteValue = result;
  } catch (e) {
    console.error("Error in receiving the respose of write: ", e);
  }
  return finalWriteValue;
};
