import {
  SelectedValueType,
  TreeItem,
  TreeTableItem,
  TreeViewTable,
  TreeViewTableColumn,
} from "@danfoss/etui-core";
import { Div } from "@danfoss/etui-system-elements";
import {
  COL1,
  COL2,
  COL3,
  COL4,
  COL5,
  COL6,
  DEFAULT,
  NAME,
  LABEL,
  MAX,
  MIN,
  VALUE,
} from "../../constants";
import {
  ParametersData,
  TreeTableParamData,
} from "../../types/controllerData.types";
import "./TreeTable.css";
import React from "react";

export interface TreeTableProps {
  ParamData: ParametersData;
}

const TreeTable = ({ ParamData }: TreeTableProps) => {
  const [parameterDetailTreeData, setParameterDetailTreeData] = React.useState<
    TreeTableItem[]
  >([]);
  const [showDefaultMenu, setShowDefaultMenu] = React.useState<boolean>(false);
  const [openSections, setOpenSections] = React.useState<SelectedValueType>([]);
  const TableColumns: TreeViewTableColumn[] = [
    {
      key: COL1,

      title: LABEL,

      width: 1.5,
    },

    {
      key: COL2,

      title: NAME,

      width: 2,
    },

    {
      key: COL3,

      title: MIN,

      width: 1,
    },

    {
      key: COL4,

      title: MAX,

      width: 1,
    },

    {
      key: COL5,

      title: DEFAULT,

      width: 1.5,
    },

    {
      key: COL6,

      title: VALUE,

      width: 2,
    },
  ];
  React.useEffect(() => {
    setShowDefaultMenu(true);
  }, []);
  React.useEffect(() => {
    if (showDefaultMenu && parameterDetailTreeData.length) {
      let sectionsIds = [];
      sectionsIds.push(parameterDetailTreeData.at(0)?.id);
      setOpenSections(sectionsIds);
    }
  }, [parameterDetailTreeData.length]);

  const getParamters = () => {
    const treeItem: TreeItem[] & TreeTableItem[] = [];

    ParamData.parameters.forEach((parameter: TreeTableParamData) => {
      treeItem.push({
        id: "idValue",
        name: "",
        treeTableRows: [
          {
            col1: parameter.label,
            col2: parameter.text,
            col3: parameter.min,
            col4: parameter.max,
            col5: parameter.defaultValue,
            col6: parameter.values,
          },
        ],
      });
    });
    return treeItem;
  };
  const displaySelectedParameterDetail = () => {
    const treeViewData: TreeTableItem[] = [];
    treeViewData.push({
      id: "",
      name: ParamData.group,
      items: getParamters().map((item, index) => ({
        ...item,
        className: index % 2 === 0 ? "" : "rowAlternate",
      })),
    });

    setParameterDetailTreeData(treeViewData);
  };

  React.useEffect(() => {
    displaySelectedParameterDetail();
  }, [ParamData]);

  const handleOpenSectionChange = (
    sectionsOpenUpdated: SelectedValueType[]
  ) => {
    setOpenSections(sectionsOpenUpdated);
  };

  return (
    <Div>
      <TreeViewTable
        styles={{
          treeView: {
            styles: {
              row: {
                root: {
                  height: "50px",
                  fontSize: "14px",
                  border: "0.5px solid #F5F5F5",
                },
              },
              root: {
                overflowY: "auto",

                height: "390px",

                position: "relative",

                overflowX: "hidden",
              },
            },
            data: parameterDetailTreeData,
          },

          tableHeader: {
            fontWeight: 900,
          },
        }}
        columns={TableColumns}
        data={parameterDetailTreeData}
        onOpenSectionChange={handleOpenSectionChange}
        openSections={openSections}
      />
    </Div>
  );
};

export default TreeTable;
