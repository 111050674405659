import * as Buffer from "buffer";
import { defaultTheme } from "@danfoss/etui-themes";
import { Notification } from "@danfoss/etui-core";
import { DEVICE_NOT_CONNECTED } from "../constants";

export const readUsbData = async (
  usbDevice: SerialPort,
  timeout: number
): Promise<Buffer> => {
  let finalReadValue: Buffer = Buffer.Buffer.from([]);

  try {
    if (!usbDevice.readable) {
      return Buffer.Buffer.from([]);
    }

    if (usbDevice.readable.locked) {
      const waitForStream = async () => {
        if (usbDevice.readable.locked) {
          return new Promise((resolve) => {
            setTimeout(() => resolve(waitForStream()), 100);
          });
        }
      };

      await waitForStream();
    }

    const reader: ReadableStreamDefaultReader<any> =
      usbDevice.readable.getReader();
    const timer = setTimeout(async () => {
      try {
        await reader.cancel();
      } catch (cancelError) {
        console.error("Error when cancelling reader:", cancelError);
      }
    }, timeout);

    const result: ReadableStreamReadResult<any> = await reader.read();
    const readValue: Buffer = result?.value || Buffer.Buffer.from([]);
    finalReadValue = readValue;

    clearTimeout(timer);
    reader.releaseLock();
  } catch (e) {
    console.error("Error in global read:", e);
    Notification.error({
      message: "Error",
      description: DEVICE_NOT_CONNECTED,
      duration: 3,
      theme: defaultTheme,
    });
  }
  return finalReadValue;
};
