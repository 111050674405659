import { getControllerData } from "../Services/controllerApi";
import {
  HostProps,
  PnuValues,
  VidDatas,
  deviceAddress,
  readResponse,
} from "../types/controllerData.types";
import { UsbRequestData } from "./usbRequest";
import { readDeviceData } from "./usbResponse";

export const fetchControllerData = async (
  hostData: HostProps,
  functionCode: string,
  usbDevice: SerialPort,
  selectedAddress: deviceAddress
): Promise<PnuValues> => {
  let finalReadScaledValue: PnuValues = {
    pnuValues: [],
  };
  try {
    const deviceAddress: string = selectedAddress?.value;
    const response: readResponse = await getControllerData(hostData);
    const allViddata: number[] = response?.pnuValues.pnu;
    const alldecimalData: number[] = response?.pnuValues.decimal;
    const finalValues: VidDatas[][] = [];
    const requestArray: string[][] = [];
    for (let item of allViddata) {
      const requestData: string[] = await UsbRequestData(
        item,
        functionCode,
        deviceAddress,
        1
      );
      requestArray.push(requestData);
    }

    const finalReadData: number[] = await readDeviceData(
      requestArray,
      usbDevice
    );

    const formattedReadData: VidDatas[] = allViddata.map(
      (pnuData: number, index: number) => ({
        pnu: pnuData,
        value: finalReadData[index],
      })
    );
    finalValues.push(formattedReadData);

    const scaledValue: VidDatas[] = [];

    formattedReadData.map((element: VidDatas, index: number) => {
      if (alldecimalData[index] === 1) {
        const scaledResult: VidDatas = {
          pnu: element.pnu,
          value: element.value / 10,
        };

        scaledValue.push(scaledResult);
      } else {
        const scaledResult: VidDatas = {
          pnu: element.pnu,
          value: element.value,
        };

        scaledValue.push(scaledResult);
      }
    });

    const scaledReadData: PnuValues = {
      pnuValues: scaledValue,
    };
    finalReadScaledValue = scaledReadData;
  } catch (error) {
    console.error("Error in reading controller data:", error);
  }
  return new Promise<PnuValues>((resolve) =>
    setTimeout(() => resolve(finalReadScaledValue))
  );
};
