import * as React from "react";
import { Split } from "../Split";

export interface SplitViewProps {
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
}

function SplitPopup({ leftPane, rightPane }: SplitViewProps) {
  const renderLeftPane = () => {
    const width = ["100%", "35%"];
    const height = ["100%", "82%"];
    return (
      <Split.Pane
        borderTop={"1px solid"}
        width={width}
        overflow="hidden"
        borderRight={`1px solid `}
        borderColor="#EBEBEB"
        bg="white"
        height={height}
      >
        {leftPane}
      </Split.Pane>
    );
  };

  const renderRightPane = () => {
    const width = ["100%", "65%"];
    const height = ["100%", "100%"];

    return (
      <Split.Pane
        width={width}
        height={height}
        borderTop={"1px solid"}
        borderColor="#EBEBEB"
        overflow="hidden"
      >
        {rightPane}
      </Split.Pane>
    );
  };

  return (
    <Split>
      {renderLeftPane()}
      {renderRightPane()}
    </Split>
  );
}

export { SplitPopup };
