import * as Buffer from "buffer";
import { writeUsbData } from "./writeUsb";
import { readUsbData } from "./readUsb";
import {
  BULK_READ_TIMEOUT,
  MAX_CONVERSION_RETRY_COUNT,
  USB_RESPONSE_TIMEOUT,
} from "../constants";

export const readDeviceData = async (
  readData: string[][],
  usbDevice: SerialPort
): Promise<number[]> => {
  const responseArray: number[] = [];
  try {
    if (usbDevice) {
      const allReadData: Uint8Array[] = [];
      let buffer: Uint8Array = new Uint8Array(0);
      for (const item of readData) {
        const readRequestData: Uint8Array = new Uint8Array(
          item.map((hex: string) => parseInt(hex, 16))
        );
        writeUsbData(usbDevice, readRequestData);

        await new Promise((resolve) => setTimeout(resolve, BULK_READ_TIMEOUT));

        try {
          const result: Buffer.Buffer = await readUsbData(
            usbDevice,
            USB_RESPONSE_TIMEOUT
          );
          buffer = new Uint8Array([...buffer, ...result]);
          while (buffer.length >= 7) {
            const readUintData: Uint8Array = buffer.slice(0, 7);
            allReadData.push(buffer.slice(0, 7));
            const readHexData: number = extractHexValues(readUintData);
            responseArray.push(readHexData);
            buffer = buffer.slice(7);
          }
        } catch (e) {
          console.error("Error in reading bulk response:", e);
        }
      }
    } else {
      console.error("USB is not connected in receiving Response");
    }
  } catch (error) {
    console.error("Error in receiving 03 response:", error);
  }
  return responseArray;
};

export const extractHexValues = (readUintData: Uint8Array) => {
  let finalResponseHexData: number = 0;
  try {
    const buffer = Buffer.Buffer.from(readUintData);
    const firstRegisterData: number = buffer.readUInt8(3);
    const secondRegisterData: number = buffer.readUInt8(4);

    const formattedValue: string =
      firstRegisterData.toString(16).padStart(2, "0") +
      secondRegisterData.toString(16).padStart(2, "0");

    const responseHexData: number = parseInt(formattedValue, 16);
    finalResponseHexData = responseHexData;
  } catch (e) {
    console.error("Error in converting read data to hex:", e);
  }
  return finalResponseHexData;
};
