import {
  Button,
  H6,
  Modal,
  Notification,
  Spinner,
  icons,
} from "@danfoss/etui-core";
import { Div, P } from "@danfoss/etui-system-elements";
import { PopupContent } from "./PopupContent";
import { useLayout } from "../components/context/layoutConfig";
import { defaultTheme } from "@danfoss/etui-themes";
import {
  CodeNumberDBResponse,
  cdfDataValue,
} from "../types/controllerData.types";
import {
  NEW_PROJECT_TEXT,
  BACK_TEXT,
  NEXT_TEXT,
  MESSAGE_TYPE,
  MESSAGE_TEXT,
  ERROR_MESSAGE_TEXT,
  WAIT_TEXT,
} from "../constants/constants";
import { getOfflineData } from "../Services";
import { CancelPopup } from "./CancelPopup";
import { useEffect, useState } from "react";
import { FinishPopup } from "./FinishPopup";

export const NewProjectPage: React.FC = () => {
  const {
    disableNext,
    setdisableNext,
    setSelectedDeviceName,
    openPopup,
    setOpenPopup,
    activeStep,
    setActiveStep,
    setOpenExitPopup,
    allCdfParameters,
    setAllCdfParameters,
    selectedCodeNumber,
    selectedSWVersion,
    setSelectedCdfFile,
  } = useLayout();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    allCdfParameters.find((item: cdfDataValue) => {
      const codeNumberMatch = item?.cdfData?.device?.Header?.CodeNumbers?.find(
        (item_Text: CodeNumberDBResponse) =>
          item_Text?.CodeNumber === selectedCodeNumber.value
      );

      if (codeNumberMatch) {
        const softwareVersion: number =
          item?.cdfData?.device?.Header?.SWVersion / 100;
        if (`${softwareVersion}` === selectedSWVersion.value) {
          setSelectedCdfFile(item);
        }
      }
    });
  }, [allCdfParameters, selectedSWVersion.value, selectedCodeNumber.value]);

  useEffect(() => {
    if (!openPopup) {
      setLoading(false);
    }
  }, [openPopup]);

  const handleOpen = async () => {
    setLoading(true);
    const offlineCdfdata: cdfDataValue[] = await getOfflineData();
    setSelectedDeviceName("");
    try {
      if (offlineCdfdata.length !== 0) {
        setOpenPopup(true);
        setAllCdfParameters(offlineCdfdata);
      } else {
        Notification.error({
          message: MESSAGE_TYPE,
          description: MESSAGE_TEXT,
          duration: 3,
          theme: defaultTheme,
        });
      }
    } catch (err) {
      Notification.error({
        message: MESSAGE_TYPE,
        description: ERROR_MESSAGE_TEXT,
        duration: 3,
        theme: defaultTheme,
      });
    }
  };

  const handleBack = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    }
    setSelectedDeviceName("");
    setdisableNext(true);
  };

  const handleNext = () => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleExitOpen = () => {
    if (activeStep === 2) {
      setOpenExitPopup(true);
    } else {
      setOpenPopup(false);
      setdisableNext(true);
    }
  };

  return (
    <Div>
      {loading && (
        <Div
          style={{
            position: "absolute",
            left: "50%",
            top: "100%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Spinner color="#34495E" />
          <H6 style={{ color: "#34495E", marginTop: "8px" }}>{WAIT_TEXT}</H6>
        </Div>
      )}
      <Div
        style={{
          background: "#303844",
          height: "90px",
          width: "100px",
          marginRight: "15px",
        }}
      >
        <Button
          variant="primary"
          iconProps={{ glyph: icons.REPORTS, size: 48 }}
          onClick={handleOpen}
          styles={{
            root: {
              height: "50px",
              width: "80px",
              background: "#34495E",
              alignItems: "center",
              mt: "10px",
              ml: "10px",
              mr: "10px",
            },
          }}
        ></Button>
        <P
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "5px",
          }}
        >
          {NEW_PROJECT_TEXT}
        </P>
      </Div>
      <Modal
        style={{
          content: { maxWidth: "1000px" },
          overlay: { pointerEvents: "auto" },
        }}
        styles={{ contentContainer: { height: "470px", width: "800px" } }}
        fullPageSize={false}
        isOpen={openPopup}
        onClose={handleExitOpen}
        shouldCloseOnOverlayClick={false}
        header={<Modal.Header title={NEW_PROJECT_TEXT} />}
        actions={
          <Div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FinishPopup />
            {activeStep === 2 && (
              <Button
                variant="primary"
                textCenter={true}
                onClick={handleBack}
                styles={{
                  root: { mr: "0.5rem", height: "30px", width: "80px" },
                }}
              >
                {BACK_TEXT}
              </Button>
            )}
            {activeStep === 1 && (
              <Button
                variant="primary"
                textCenter={true}
                disabled={disableNext}
                onClick={handleNext}
                styles={{
                  root: {
                    mr: "0.5rem",
                    height: "30px",
                    width: "80px",
                  },
                }}
              >
                {NEXT_TEXT}
              </Button>
            )}
            <CancelPopup />
          </Div>
        }
      >
        <PopupContent
          useOnlyNumber={false}
          activeDataStep={activeStep}
          allCdfData={allCdfParameters}
        />
      </Modal>
    </Div>
  );
};

export default NewProjectPage;
