import React from "react";
import {
  updatedReadData,
  cdfDataValue,
  controllerInfoData,
  newProjectDataType,
  deviceAddress,
} from "../../types/controllerData.types";
import { TreeItem, SelectInputOption } from "@danfoss/etui-core";

export interface LayoutContextProps {
  selectedItem: TreeItem | undefined;
  setSelectedItem: (value: TreeItem) => void;
  selectedparam: updatedReadData[] | any;
  setSelectedParam: (value: updatedReadData[]) => void;
  isdisplayParam: boolean;
  setisdisplayParam: (value: boolean) => void;
  selectedDeviceName: string;
  setSelectedDeviceName: (value: string) => void;
  disableNext: boolean;
  setdisableNext: (value: boolean) => void;
  selectedCodeNumber: SelectInputOption;
  setSelectedCodeNumber: (value: SelectInputOption) => void;
  selectedSWVersion: SelectInputOption;
  setSelectedSWVersion: (value: SelectInputOption) => void;
  newProjectDescription: string;
  setNewProjectDescription: (value: string) => void;
  newProjectName: string;
  setNewProjectName: (value: string) => void;
  disableFinish: boolean;
  setDisableFinish: (value: boolean) => void;
  openPopup: boolean;
  setOpenPopup: (value: boolean) => void;
  activeStep: number;
  setActiveStep: (value: number) => void;
  openExitPopup: boolean;
  setOpenExitPopup: (value: boolean) => void;
  allCdfParameters: cdfDataValue[];
  setAllCdfParameters: (value: cdfDataValue[]) => void;
  path: string;
  setPath: (value: string) => void;
  controllerInfoData: controllerInfoData[];
  setControllerInfoData: (value: controllerInfoData[]) => void;
  controllerImg: string;
  setControllerImg: (value: string) => void;
  selectedCdfFile: cdfDataValue;
  setSelectedCdfFile: (value: cdfDataValue) => void;
  newProjectContent: newProjectDataType;
  setNewProjectContent: (value: newProjectDataType) => void;
  defaultProjectData: newProjectDataType;
  setDefaultProjectData: (value: newProjectDataType) => void;
  openProjectData: cdfDataValue;
  setOpenProjectData: (value: cdfDataValue) => void;
  openProject: boolean;
  setOpenProject: (value: boolean) => void;
  fileExtension: string;
  setFileExtension: (value: string) => void;
  openedProjectName: string;
  setOpenedProjectName: (value: string) => void;
  controllerFamily: string;
  setControllerFamily: (value: string) => void;
  isElectronApp: boolean;
  setIsElectronApp: (value: boolean) => void;
  isOnline: boolean;
  setIsOnline: (value: boolean) => void;
  uploadNameWithType: string;
  setUploadNameWithType: (value: string) => void;
  savePopupOpen: boolean;
  setSavePopupOpen: (value: boolean) => void;
  selectedAddress: deviceAddress;
  setSelectedAddress: (value: deviceAddress) => void;
  openSettings: boolean;
  setOpenSettings: (value: boolean) => void;
  disableDone: boolean;
  setDisableDone: (value: boolean) => void;
  onlineControllerImg: string;
  setOnlineControllerImg: (value: string) => void;
}

export const LayoutContext = React.createContext<LayoutContextProps | null>(
  null
);
