import { fetchData } from "../action/fetch";
import { CDF_URL } from "../constants";
import { CDFProps } from "../Pages";

export const getCdfData = async (cdfData: CDFProps) => {
  try {
    const cdfurl: string = process.env.APPSETTING_CDF_URL || CDF_URL;
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      mode: "no-cors",
    };
    const cdfFileData = await fetchData(cdfurl, {
      cdfValue: cdfData,
      headers,
    });
    return cdfFileData?.data;
  } catch (error) {
    console.error("Error parsing CDF API response:", error);
  }
};
