import { useLayout } from "../components/context/layoutConfig";
import { useState, useEffect } from "react";
import { Div } from "@danfoss/etui-system-elements";
import { Container, H6 } from "@danfoss/etui-core";
import * as Buffer from "buffer";
import { BASE64_TEXT, IMAGE_BASE64 } from "../constants";
import { controllerInfoData } from "../types";

export const ControllerInfoPage = () => {
  const {
    selectedDeviceName,
    controllerInfoData,
    setControllerImg,
    controllerImg,
  } = useLayout();
  const [supportImg, setSupportImg] = useState<string>("");
  const [deviceDetials, setDeviceDetials] = useState<string>("");

  useEffect(() => {
    const fetchselectedImage = () => {
      const dataItem = controllerInfoData?.find(
        (item: controllerInfoData) => item?.name === selectedDeviceName
      );
      if (dataItem) {
        setControllerImg(dataItem?.productImage);
        setSupportImg(dataItem?.supportImage);
        const controllerDetials: Buffer = Buffer.Buffer.from(
          dataItem?.controllerInfo,
          BASE64_TEXT
        );
        const formatedcontrollerData: string = controllerDetials
          .toString()
          .replace(/^"|"$|'/g, "");
        setDeviceDetials(formatedcontrollerData);
      }
    };
    fetchselectedImage();
  }, [selectedDeviceName, controllerInfoData]);

  return (
    <>
      {selectedDeviceName !== "" ? (
        <Container styles={{ root: { overflowY: "visible" } }}>
          <Div height={25} borderBottom={"1px solid #B4BCC3"}>
            <H6 textAlign={"center"}>{selectedDeviceName}</H6>
          </Div>
          <Div>
            {controllerImg !== null && (
              <img
                src={`${IMAGE_BASE64}${controllerImg}`}
                alt=""
                style={{
                  height: "130px",
                  width: "220px",
                  marginTop: "20px",
                  marginRight: "20px",
                  border: "1px solid #B4BCC3",
                  position: "fixed",
                  pointerEvents: "none",
                }}
              />
            )}
            {supportImg !== null && (
              <img
                src={`${IMAGE_BASE64}${supportImg}`}
                alt=""
                style={{
                  height: "130px",
                  width: "250px",
                  marginTop: "20px",
                  marginLeft: "235px",
                  position: "fixed",
                  border: "1px solid #B4BCC3",
                  pointerEvents: "none",
                }}
              />
            )}
          </Div>
          <Container
            styles={{
              root: {
                height: "215px",
                width: "480px",
                overflowY: "auto",
                mt: "170px",
                mr: "20px",
                border: "1px solid #B4BCC3",
                textAlign: "justify",
              },
            }}
          >
            {deviceDetials.split("\n").map((line, index) => (
              <Div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                key={index}
              >
                {line}
              </Div>
            ))}
          </Container>
        </Container>
      ) : (
        ""
      )}
    </>
  );
};
