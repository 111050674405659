import {
  FIRSTBYTE,
  IDENTIFICATION_TIMEOUT,
  USB_RESPONSE_TIMEOUT,
} from "../constants";
import { readUsbData } from "./readUsb";
import { writeUsbData } from "./writeUsb";
import * as crc from "crc";
import * as Buffer from "buffer";

export const usbDeviceIdentification = async (
  usbDevice: SerialPort,
  deviceAddress: string
): Promise<string> => {
  let finalIdentificationResponse: string = "";
  try {
    const IdentificationData = ["0x2b", "0x0e", "0x02", "0x00"];

    IdentificationData.unshift(deviceAddress);
    const numericData: number[] = IdentificationData.map((hexString: string) =>
      parseInt(hexString, 16)
    );
    const bufferData = Buffer.Buffer.from(numericData);
    const crc16Data: string = crc
      .crc16modbus(bufferData)
      .toString(16)
      .toUpperCase();
    const formattedCrc: string =
      crc16Data.length < 4
        ? FIRSTBYTE.repeat(4 - crc16Data.length) + crc16Data
        : crc16Data;
    const firstCrcData: string = formattedCrc.slice(0, 2);
    const secondCrcData: string = formattedCrc.slice(2);
    const formattedFirstCrcData: string = `0x${secondCrcData}`;
    const formattedSecondCrcData: string = `0x${firstCrcData}`;

    IdentificationData.push(formattedFirstCrcData, formattedSecondCrcData);
    const readRequestData: Uint8Array = new Uint8Array(
      IdentificationData.map((hex: string) => parseInt(hex, 16))
    );
    writeUsbData(usbDevice, readRequestData);

    await new Promise((resolve) => setTimeout(resolve, IDENTIFICATION_TIMEOUT));

    const receivedData: Buffer = await readUsbData(
      usbDevice,
      USB_RESPONSE_TIMEOUT
    );
    const finalIdentificationValue: string = new TextDecoder().decode(
      receivedData
    );
    finalIdentificationResponse = finalIdentificationValue;
  } catch (error) {
    console.error("Error while Identifying the USB device:", error);
  }
  return finalIdentificationResponse;
};
