import "./App.css";
import { Div } from "@danfoss/etui-system-elements";
import LoadingPage from "./Pages/LoadingPage";
import { LayoutProvider } from "./components/context/layoutProvider";
import React from "react";


function App() {
 
  return (
    <Div>
      <LayoutProvider>
      <LoadingPage />
      
      </LayoutProvider>
    </Div>
  );
}

export default App;
