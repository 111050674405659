import * as React from 'react';
import { DivProps } from '@danfoss/etui-system-elements';
import * as S from './styles';

type PaneProps = DivProps;

interface Props {
  children?: React.ReactNode
}

function Pane({ children, ...props }: PaneProps) {
  return <S.PaneDiv {...props}>{children}</S.PaneDiv>;
}

function Split({ children}: Props) {
  return <S.Main>{children}</S.Main>;
}

Split.Pane = Pane;

export { Split };
