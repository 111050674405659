import React, { useState } from "react";
import { LayoutContext, LayoutContextProps } from "./layoutContext";
import { TreeItem, SelectInputOption } from "@danfoss/etui-core";
import {
  updatedReadData,
  cdfDataValue,
  controllerInfoData,
  newProjectDataType,
  deviceAddress,
} from "../../types/controllerData.types";
import { JSON_EXTENSION } from "../../constants";

interface LayoutProviderProps {
  children: React.ReactNode;
}
export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [selectedItem, setSelectedItem] = React.useState<
    TreeItem | undefined
  >();
  const [selectedparam, setSelectedParam] = React.useState<
    LayoutContextProps | undefined | updatedReadData[]
  >();
  const [isdisplayParam, setisdisplayParam] = React.useState<boolean>(true);
  const [selectedDeviceName, setSelectedDeviceName] =
    React.useState<string>("");
  const [disableNext, setdisableNext] = React.useState<boolean>(true);
  const [selectedCodeNumber, setSelectedCodeNumber] =
    React.useState<SelectInputOption>({ value: "", label: "" });
  const [selectedSWVersion, setSelectedSWVersion] =
    React.useState<SelectInputOption>({ value: "", label: "" });
  const [newProjectDescription, setNewProjectDescription] =
    React.useState<string>("");
  const [newProjectName, setNewProjectName] = React.useState<string>("");
  const [disableFinish, setDisableFinish] = React.useState<boolean>(true);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [openExitPopup, setOpenExitPopup] = React.useState<boolean>(false);
  const [allCdfParameters, setAllCdfParameters] = React.useState<
    cdfDataValue[]
  >([]);
  const [path, setPath] = useState<string>("");
  const [controllerInfoData, setControllerInfoData] = React.useState<
    controllerInfoData[]
  >([]);
  const [controllerImg, setControllerImg] = useState<string>("");
  const [selectedCdfFile, setSelectedCdfFile] = useState<cdfDataValue>(
    {} as cdfDataValue
  );
  const [newProjectContent, setNewProjectContent] =
    useState<newProjectDataType>({} as newProjectDataType);
  const [defaultProjectData, setDefaultProjectData] =
    useState<newProjectDataType>({} as newProjectDataType);
  const [openProjectData, setOpenProjectData] = useState<cdfDataValue>(
    {} as cdfDataValue
  );
  const [openProject, setOpenProject] = useState<boolean>(false);
  const [fileExtension, setFileExtension] = useState<string>(JSON_EXTENSION);
  const [openedProjectName, setOpenedProjectName] = useState<string>("");
  const [controllerFamily, setControllerFamily] = useState<string>("");
  const [isElectronApp, setIsElectronApp] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [uploadNameWithType, setUploadNameWithType] = useState<string>("");
  const [savePopupOpen, setSavePopupOpen] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<deviceAddress | any>();
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [disableDone, setDisableDone] = useState(true);
  const [onlineControllerImg, setOnlineControllerImg] = useState<string>("");

  return (
    <LayoutContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        selectedparam,
        setSelectedParam,
        isdisplayParam,
        setisdisplayParam,
        selectedDeviceName,
        setSelectedDeviceName,
        disableNext,
        setdisableNext,
        selectedCodeNumber,
        setSelectedCodeNumber,
        selectedSWVersion,
        setSelectedSWVersion,
        newProjectDescription,
        setNewProjectDescription,
        newProjectName,
        setNewProjectName,
        disableFinish,
        setDisableFinish,
        openPopup,
        setOpenPopup,
        activeStep,
        setActiveStep,
        openExitPopup,
        setOpenExitPopup,
        allCdfParameters,
        setAllCdfParameters,
        path,
        setPath,
        controllerImg,
        setControllerImg,
        selectedCdfFile,
        setSelectedCdfFile,
        newProjectContent,
        setNewProjectContent,
        controllerInfoData,
        setControllerInfoData,
        defaultProjectData,
        setDefaultProjectData,
        openProjectData,
        setOpenProjectData,
        openProject,
        setOpenProject,
        fileExtension,
        setFileExtension,
        openedProjectName,
        setOpenedProjectName,
        controllerFamily,
        setControllerFamily,
        isElectronApp,
        setIsElectronApp,
        isOnline,
        setIsOnline,
        uploadNameWithType,
        setUploadNameWithType,
        savePopupOpen,
        setSavePopupOpen,
        selectedAddress,
        setSelectedAddress,
        openSettings,
        setOpenSettings,
        disableDone,
        setDisableDone,
        onlineControllerImg,
        setOnlineControllerImg,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
